<template lang="html">

  <section aria-describedby="dialog-body-id-7" aria-labelledby="dialog-heading-id-7" class="slds-popover slds-popover_large slds-nubbin_top-right" role="dialog" 
  style="position:absolute;top:calc(100% + 12px);right:-12px"
  >
    <button v-on:click="close" class="slds-button slds-button_icon slds-button_icon-small slds-float_right slds-popover__close" title="Close dialog">
      <svg class="slds-button__icon" aria-hidden="true">
        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
      </svg>
      <span class="slds-assistive-text">Close dialog</span>
    </button>

    <header class="slds-popover__header">
      <h2 class="slds-text-heading_small" id="dialog-heading-id-7">Notifications</h2>
    </header>

    <div class="slds-popover__body slds-p-around_none" id="dialog-body-id-7">
      <slot></slot>

    </div>
  </section>

</template>

<script>

export default {
  name: 'gnetPopover',
  components: {
  },
  computed: {
  },
  props: {
  },
  data() {
    return {
    }
  },
  mounted: function() {
  },
  async created() {

  },
  methods: {
    close: function() {
        this.$emit('close');
    }
  }
}
</script>

<style scoped>
</style>

<template>
  <div id="app">
    <gnetLogin v-if="!$store.getters.logged" v-on:login="doLogin"/>
    <router-view v-if="$store.getters.logged"/>
  </div>
</template>

<script>
import gnetLogin from './components/login.vue'
import apiclient from '@/apiclient.js'
import numeral from 'numeral'
import metaremote from '@/metaremote.js'
//import moment from 'moment'

export default {
  name: 'app',
  components: {
    gnetLogin
  },
   mounted: function() {
    var self=this;
    // eslint-disable-next-line no-undef
    gapi.load('auth2', function() {
      // eslint-disable-next-line no-undef
      self.$store.commit('SET_AUTH', gapi.auth2.init() );
    });

    if (numeral.locales['it'] === undefined) {
      numeral.register('locale', 'it', {
          delimiters: { thousands: '.', decimal: ',' },
          abbreviations: { thousand: 'k', million: 'm', billion: 'b', trillion: 't' },
          currency: { symbol: '€'}
      });

      numeral.locale('it');
    }

  },
  methods: {
    doLogin: function() {
      console.log('present myself:'+this.$store.state.user.email);
//      console.log('present myid_token:'+this.$store.state.user.id_token);
      
      var connection={
        myid: this.$store.state.user.email,
        myid_token: this.$store.state.user.id_token,
        commit: this.$store.commit,
        userdata: {},
        zona: '',
        key:'',
       };
      var self=this;
      apiclient.getKey('priv.pem').then(function(key) {
        connection.key=key.data;
        apiclient.login(connection).then(function(resp) {
          self.$store.commit('SET_CONNECTION', connection );
          self.$store.commit('SET_CONNECTION_TOKEN', resp );
          self.$store.commit('SET_LOGIN', true);
        }).catch(function(err){
          console.log(err);
        })        
      }).catch(function(err){
        console.log(err);
      })
    }
  }
}
</script>

<style>
</style>

<template lang="html">

<div class="slds-form-element">
  
  <div class="slds-form-element__control">
    <div class="slds-combobox_container">

      <div v-bind:class="{'slds-is-open':show}" class="slds-combobox slds-dropdown-trigger slds-dropdown-trigger_click" aria-expanded="true" aria-haspopup="listbox" role="combobox">

        <div class="slds-combobox__form-element slds-input-has-icon slds-input-has-icon_right" role="none">
          <input v-on:focus="focus(true)" v-on:blur="preblur(false)" v-on:keydown="change" type="text" 
            v-bind:class="{'slds-has-focus':show}"
            class="slds-input slds-combobox__input slds-combobox__input-value"
            aria-activedescendant="option1" aria-autocomplete="list" aria-controls="listbox-id-4"
            autoComplete="off" role="textbox" placeholder="Search..." v-model="value" />
          <span class="slds-icon_container slds-icon-utility-search slds-input__icon slds-input__icon_right">
            <svg class="slds-icon slds-icon slds-icon_x-small slds-icon-text-default" aria-hidden="true">
              <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#search"></use>
            </svg>
          </span>
        </div>

        <div v-if="show" id="listbox-id-4" class="slds-dropdown slds-dropdown_length-with-icon-7 slds-dropdown_fluid" role="listbox">
          <ul class="slds-listbox slds-listbox_vertical" role="presentation">

            <li role="presentation" class="slds-listbox__item" >
              <div aria-selected="true" id="option0"
                class="slds-media slds-listbox__option slds-listbox__option_entity slds-listbox__option_term slds-has-focus"
                role="option">
                <span class="slds-media__figure slds-listbox__option-icon">
                  <span class="slds-icon_container slds-icon-utility-search" title="Search for term: ">
                    <svg class="slds-icon slds-icon_x-small slds-icon-text-default" aria-hidden="true">
                      <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#search"></use>
                    </svg>
                    <span class="slds-assistive-text">Search for term: </span>
                  </span>
                </span>
                <span class="slds-media__body">
                  <span class="slds-listbox__option-text slds-listbox__option-text_entity">{{value}}</span>
                </span>
              </div>
            </li>

            <li v-on:click="click(index)" role="presentation" class="slds-listbox__item" v-for="(f,index) in founded" v-bind:key="f.id">
              <div id="option1"
                class="slds-media slds-listbox__option slds-listbox__option_entity slds-listbox__option_has-meta"
                role="option">
                <span class="slds-media__figure slds-listbox__option-icon">
                  <span class="slds-icon_container slds-icon-standard-account">
                    <svg class="slds-icon slds-icon_small" aria-hidden="true">
                      <use v-bind:xlink:href="'/assets/icons/standard-sprite/svg/symbols.svg#'+object"></use>
                    </svg>
                  </span>
                </span>
                <span class="slds-media__body">
                  <span class="slds-listbox__option-text slds-listbox__option-text_entity">
                    <span>
                      <mark>{{f.name}}</mark>
                    </span>
                  </span>
                  <span v-if="false" class="slds-listbox__option-meta slds-listbox__option-meta_entity">{{object}} • {{f.value}}</span>
                </span>
              </div>
            </li>

          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

</template>

<script>

export default {
  name: 'gnetLookup',
  components: {
  },
  computed: {
  },
  props: {
    label: String,
    object: String,
    founded: Array,
    selected: String
  },
  data() {
    return {
      value:'',
      timer:'',
      timerBlur:'',
      show: false
    }
  },
  mounted: function() {
  },
  async created() {

  },
  methods: {
    preblur: function() {
      console.log('preblur');
      if (this.timerBlur) clearTimeout(this.timerBlur);
      var self=this;
      this.timerBlur=setTimeout(function() {
        console.log('blurring');
        self.show=false;
      }, 200);
    },
    focus: function(status) {
      this.show=status;
    },
    change: function() {
      console.log('clack');

      if (this.timer) clearTimeout(this.timer);
      var self=this;
      this.timer=setTimeout(function() {
        console.log('search');
        self.show=true;
        self.$emit('change', self.value);
      }, 500);

    },
    click: function(index) {
      console.log('click');
      console.log(index);
      this.value=this.founded[index].name;
      this.$emit('click', index);
    },
  }
}
</script>

<style scoped>
</style>

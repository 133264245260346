<template lang="html">

  <li class="slds-global-actions__item">
    <div class="slds-dropdown-trigger slds-dropdown-trigger_click">
      <button v-bind:class="{'slds-incoming-notification':alert}" v-on:click="show=true"
class="slds-button slds-button_icon slds-button_icon slds-button_icon-container slds-button_icon-small slds-global-actions__notifications slds-global-actions__item-action" 
        v-bind:title="msg" aria-live="assertive"
        aria-atomic="true">
        <svg class="slds-button__icon slds-global-header__icon" aria-hidden="true">
          <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#notification"></use>
        </svg>
        <span class="slds-assistive-text">{{msg}}</span>
      </button>
      <span v-bind:class="{'slds-incoming-notification slds-show-notification':alert}" aria-hidden="true" class="slds-notification-badge">{{nmsg}}</span>

      <gnetPopover v-if="show" v-on:close="show=false">

      <ul>

        <li v-for="(n,index) in notifications" v-bind:key="n.order" v-bind:class="{'slds-global-header__notification_unread':n.unreaded}" class="slds-global-header__notification " >

          <div class="slds-media slds-has-flexi-truncate slds-p-around_x-small">
            <div class="slds-media__figure">
              <span class="slds-avatar slds-avatar_small">
                <img v-bind:alt="n.who"  v-bind:src="'/assets/images/'+(n.avatar?n.avatar:'avatar2.jpg')" v-bind:title="n.who" />
              </span>
            </div>
            <div class="slds-media__body">
              <div class="slds-grid slds-grid_align-spread">
                <a href="javascript:void(0);" class="slds-text-link_reset slds-has-flexi-truncate" v-on:click="readed(index)">
                  <h3 class="slds-truncate" v-bind:title="n.who+' mentioned you'">
                    <strong>{{n.who}} mentioned you</strong>
                  </h3>
                  <p class="slds-truncate" v-bind:title="n.content">{{n.content}}</p>
                  <p class="slds-m-top_x-small slds-text-color_weak">10 hours ago
                    <abbr v-if="n.unreaded" class="slds-text-link slds-m-horizontal_xxx-small" title="unread">●</abbr>
                  </p>
                </a>
              </div>
            </div>
          </div>
          
        </li>

      </ul>

      </gnetPopover>

    </div>
  </li>

</template>

<script>
import gnetPopover from './popover.vue'

export default {
  name: 'gnetHeaderNotification',
  components: {
    gnetPopover
  },
  computed: {
    nmsg: function() {
      if (!this.notifications) return 0;
      return this.notifications.reduce(function(total, notification) {
         if (notification.unreaded) return total+1;
         return total; 
      },0);
    },
    alert: function() {
      if (this.nmsg>0) return true;
      return false;
    },
    msg: function() {
      if (!this.notifications) return 'no new notifications';
      if (this.notifications.length==0) {
        return 'no new notifications';
      }
      if (this.notifications.length==1) {
        return '1 new notification';
      }
      return this.notifications.length+' new notifications';
    }
  },
  props: {
    notifications: Array,
  },
  data() {
    return {
      show:false
    }
  },
  mounted: function() {
  },
  async created() {

  },
  methods: {
    readed: function(index) {
      this.$emit('readed',index);
    }
  }
}
</script>

<style scoped>
</style>

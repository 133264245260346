<template>
  <div class="mandati">
    <gnetHeader></gnetHeader>

    <gnetTabBar
      v-bind:titoloapp="'Backoffice'"
      v-bind:tabs="$store.state.tabs"
      v-bind:active_tab="$store.state.active_tab"
      v-on:click="changeTab($event)"
    />
    <div class="slds-grid">
      <div class="slds-col slds-tabs_card slds-size_2-of-3" style="margin: 5px;">
        <div class="slds-card slds-card_boundary headerBottomBorder forceRelatedListCardDesktop">
        <div class="highlights slds-clearfix slds-page-header slds-page-header_record-home fixed-position">
        <gnetRecordHeader
          v-bind:object="'account'"
          v-bind:title="record.idCodCliente__c"
          v-bind:fields="headlineFields"
          v-bind:actions="headlineActions"
          v-on:click="actionClick($event)"
        />

        <article class="slds-card">
          <div class="slds-card__body slds-card__body_inner">

            <gnetFormMask v-on:click="toggleSection($event)" v-bind:sections="sections">

            </gnetFormMask>

          </div>
          <footer class="slds-card__footer">
          </footer>
        </article>
        <div v-if="$store.state.connection.userdata.Visibilit_documenti_su_opp__c !== undefined && $store.state.connection.userdata.Visibilit_documenti_su_opp__c == true" class="slds-card slds-card_boundary headerBottomBorder forceRelatedListCardDesktop">
          <gnetRecordHeader
            v-bind:object="'document'"
            v-bind:title="'Documenti'"
          />
          <gnetTable
            v-bind:cols="docucols"
            v-bind:records="documenti"
            v-on:azione="upDocAcc($event)"
            v-bind:linkspecial="true"
          >      
          </gnetTable>
        </div>
    <gnetModal
      v-if="editAccount"
      v-bind:title="'Modifica Account: '+record.Name"
      v-bind:object="'account'"
      v-on:cancel="editAccount=false"
      v-on:submit="updateAccount"
    >
        <div class="slds-clearfix slds-card groupDependentFieldEnabled  allow-horizontal-form wide-input-break full-width forceDetailPanelDesktop">
          <div class="slds-grid slds-gutters_small full cols-2 forcePageBlockSectionRow">
              <div class="slds-col slds-grid slds-has-flexi-truncate  full forcePageBlockItem forcePageBlockItemView">
                <div class="slds-form-element slds-form-element_readonly slds-grow slds-hint-parent override--slds-form-element">
                  <div class="test-id__field-label-container slds-form-element__label">
                  <span class="test-id__field-label" style="padding-right:35px;">Ragione Sociale</span>
                  </div>
                  <div class="slds-form-element__control slds-grid itemBody">
                  <input v-model="record.Name" 
                   class="test-id__field-value slds-form-element__static slds-grow  is-read-only slds_input"
                    maxlength="100"
                    type="text" />
                    </div>
                </div>
              </div>
              <div class="slds-col slds-grid slds-has-flexi-truncate  full forcePageBlockItem forcePageBlockItemView">
                <div class="slds-form-element slds-form-element_readonly slds-grow slds-hint-parent override--slds-form-element">
                  <div class="test-id__field-label-container slds-form-element__label">
                  <span class="test-id__field-label" style="padding-right:35px;">Partita Iva</span>
                  </div>
                  <div class="slds-form-element__control slds-grid itemBody">
                  <input
                    class="test-id__field-value slds-form-element__static slds-grow  is-read-only slds_input"
                    v-model="record.partIva__c" 
                    maxlength="100"
                    type="text"
                  />
                  </div>
                </div>
              </div>
            </div>
            <div class="slds-grid slds-gutters_small full cols-2 forcePageBlockSectionRow">
              <div class="slds-col slds-grid slds-has-flexi-truncate  full forcePageBlockItem forcePageBlockItemView">
                <div class="slds-form-element slds-form-element_readonly slds-grow slds-hint-parent override--slds-form-element">
                  <div class="test-id__field-label-container slds-form-element__label">
                  <span class="test-id__field-label" style="padding-right:35px;">Codice Fiscale</span>
                  </div>
                  <div class="slds-form-element__control slds-grid itemBody">
                  <input v-model="record.codiceFisc__c" 
                   class="test-id__field-value slds-form-element__static slds-grow  is-read-only slds_input"
                    maxlength="100"
                    type="text" />
                    </div>
                </div>
              </div>
              <div class="slds-col slds-grid slds-has-flexi-truncate  full forcePageBlockItem forcePageBlockItemView">
                <div class="slds-form-element slds-form-element_readonly slds-grow slds-hint-parent override--slds-form-element">
                  <div class="test-id__field-label-container slds-form-element__label">
                  <span class="test-id__field-label" style="padding-right:35px;">Telefono</span>
                  </div>
                  <div class="slds-form-element__control slds-grid itemBody">
                  <input
                    class="test-id__field-value slds-form-element__static slds-grow  is-read-only slds_input"
                    v-model="record.Phone" 
                    maxlength="100"
                    type="text"
                  />
                  </div>
                </div>
              </div>
            </div>
        </div>
    </gnetModal>
        </div>
        </div>
        
      </div>
      
      <div class="slds-col">
        <div class="slds-tabs_card" style="margin: 5px;">
          <div class="slds-card slds-card_boundary headerBottomBorder forceRelatedListCardDesktop">
            <gnetTimeline v-bind:records="timeline" v-bind:currentRecord="record" v-on:finish="getTimeline()">
            </gnetTimeline>
          </div>
        </div>
        <div class="slds-tabs_card" style="margin: 5px;" v-if="$store.state.connection.userdata.Permesso_upload_doc_su_acc__c !== undefined && $store.state.connection.userdata.Permesso_upload_doc_su_acc__c == true">
          <div class="slds-card slds-card_boundary forceBaseCard flexipageCard" style="margin-top: 15px">
            <uploadDocs v-on:click="getDocu"  v-bind:utentePort="$store.state.connection.userdata.Id" v-bind:rId="record.Id" v-bind:related="'Account'" v-bind:rec="record" v-bind:ent="'Account'">
            </uploadDocs>
          </div>
        </div>
      </div>
    </div>

    <gnetSpinner v-bind:busy="busy"></gnetSpinner>

  </div>
</template>

<script>
// @ is an alias to /src
// import numeral from 'numeral'
import gnetHeader from '../components/header.vue'
import gnetTabBar from '../components/tabbar.vue'
import gnetRecordHeader from '../components/recordheader.vue'
import gnetFormMask from '../components/formmask.vue'
import gnetSpinner from '../components/spinner.vue'
import gnetTimeline from '../components/timeline.vue'
import apiclient from '@/apiclient.js'
import gnetMixLib from '@/mixin.js'
import gnetModal from '../components/modal.vue'
import gnetTable from '../components/table.vue'
import uploadDocs from '../components/uploadDocs.vue'

export default {
  name: 'Account',
  mixins: [gnetMixLib],
  components: {
    gnetHeader,
    gnetTabBar,
    gnetRecordHeader,
    gnetFormMask,
    gnetTimeline,
    gnetModal,
    gnetSpinner,
    gnetTable,
    uploadDocs
  },
  data: function() {
    return {
      busy: false,
      recordId:'',
      record: {},

      editAccount: false,
      fields:[],
      fieldsstring: '',
      headlineFields: [],
      headlineActions: [],
      sections:[],

      apporticols:[],
      apportifieldsstring:'',
      apporti:[],

      docucols:[],
      docufieldsstring: '',
      documenti:[],

      timeline: [],

      // pareri
      showCommento: false,
      showParere: false,
      esiti: [
        { label:'Positivo', value:'Positivo'},
        { label:'Negativo', value:'Negativo'},
      ],
      esito:'',
      esitoNote:'',

      // tariffari
      showTariffario: false,
      tariffari: [],
      tariffario:'',
      tariffarioNote:'',

    };
  },
  mounted: function() {
    this.setupHeaderFields();
    this.setupRecordFields();
    this.setupQueryFields();
    this.setupDocumento();
    this.getRecord();
    this.getDocu();
    this.getTimeline();
  },
  methods: {
    removeAlert: function(fields, alert) {
      if (!fields) return fields;
      var a=fields.split(';');
      var b=[];
      a.forEach(function(v){
        if (alert!=v)
          b.push(v);
      });
      return b.join(';');
    },
    toggleSection: function(index) {
      this.sections[index].opened=!this.sections[index].opened;
    },
    getRecord: function() {
      var self=this;
      this.recordId=this.$route.params.id;
      var soql='select '+this.fieldsstring+' from Account where id=\''+this.recordId+'\'';
      console.log(soql);
      apiclient.query(this.$store.getters.connection, soql).then(function(data) {
        console.log('records:'+data.length);
        console.log(data)
        if (data.length>0)
          self.record=data[0];
        self.updateRecordFields();
      }).catch(function(err){
        console.log(err);
      })
    },
     upDocAcc: function(evt){
       console.log('solleeeeecita');
      var self=this;
      var azione = evt[0];
      var r = evt[1];
       var doc = {};
      if(azione == 'spub') doc = {Avvia_Spubblicazione__c: true, caricato_da_BackOffice__c: true, Utente_Portale_Spubblica__c: this.$store.state.connection.userdata.Id};
      if(azione == 'soll') doc = {Sollecito_Pubblicazione__c: true};
      if(azione == 'elim') doc = {Elimina_record__c: true};
      apiclient.update(this.$store.getters.connection, 'Documento__c', r.Id, doc)
      .then(function(resp) {
          console.log(resp);
          self.getDocu();
          if(azione == 'spub')alert('Documento inviato per la rimozione della pubblicazione!');
          if(azione == 'soll')alert('Sollecito inviato!');
          if(azione == 'elim')alert('Documento eliminato!');
      })
      .catch(function(err) {
          console.log(err);
          alert('Errore nell\'aggiornamento: '+err);
      })
    },
    updateRecordFields: function() {
      // header
      this.updateFieldsValue(this.headlineFields);
      // record
      this.sections.forEach(function(s) {
        this.updateFieldsValue(s.fields);
      }, this);

      // Modifica Tariffario;Inserire Parere;Effettuare Callback
      this.headlineActions=[];
      this.headlineActions.push( /*{ name:'Modifica', class:'slds-button_neutral'  }*/ );
    },
    setupQueryFields: function() {
      var mfields=new Map();

      // id e name sempre
      mfields.set('Id', null);
      mfields.set('Name', null);
      
      // header
      this.mapFields(mfields, this.headlineFields);
      // record
      this.sections.forEach(function(s) {
        this.mapFields(mfields, s.fields);
      },this);
      // riassume
      this.fields=this.uniqueFields(mfields);
      this.fieldsstring=this.fields.join(',');
    },
    setupHeaderFields: function() {
      var con = this.$store.getters.connection;
      if(con.userdata.Name != 'Ufficio Archivio')this.headlineFields.push( { value:'', label:'Nome e Cognome', name:'Name', type:'string' } );
      //this.headlineFields.push( { value:'', label:'Partita Iva', name:'partIva__c', type:'string' } );
      //this.headlineFields.push( { value:'', label:'Codice Fiscale', name:'codiceFisc__c', type:'string' } );
      this.headlineFields.push( { value:'', label:'Codice Cliente', name:'idCodCliente__c', type:'string' } );
      this.headlineFields.push( { value:'', label:'Cellulare', name:'PersonMobilePhone', type:'string' } );
      if(con.userdata.Name != 'Ufficio Archivio')this.headlineFields.push( { value:'', label:'Email', name:'PersonEmail', type:'string' } );

    },
    actionClick: function(actionName){
      if(actionName == 'Modifica') this.editAccount = true;
    },
    updateAccount: function(){
      var self = this;
      var acc = this.record;
      delete acc.attributes;
      delete acc.Id;
      apiclient.update(this.$store.getters.connection, 'Account', this.recordId, acc)
        .then(function(resp) {
            console.log(resp);
            self.editAccount = false;
            self.getRecord();
            alert('Account aggiornato!');
        })
        .catch(function(err) {
            console.log(err);
            alert('Errore nell\'aggiornamento: '+err);
        })
    },
    setupRecordFields: function() {
      var con = this.$store.getters.connection;
      
      var sectionFields=[];
      if(con.userdata.Name != 'Ufficio Archivio')sectionFields.push( { id:1, value:'', title:'Nome e Cognome', name:'Name', type:'text', placeholder:'scrivi...', tabindex:0, disabled: true} );
      sectionFields.push( { id:2, value:'', title:'Partita Iva', name:'partIva__c', type:'text', placeholder:'scrivi...', tabindex:0, disabled: true} );
      if(con.userdata.Name != 'Ufficio Archivio') sectionFields.push( { id:3, value:'', title:'Codice Fiscale', name:'codiceFisc__c', type:'text', placeholder:'scrivi...', tabindex:0, disabled: true} );
      sectionFields.push( { id:4, value:'', title:'Codice Cliente', name:'idCodCliente__c', type:'text', placeholder:'scrivi...', tabindex:0, disabled: true} );
      sectionFields.push( { id:5, value:'', title:'Cellulare', name:'PersonMobilePhone', type:'text' , placeholder:'scrivi...', tabindex:0, disabled: true} );
      if(con.userdata.Name != 'Ufficio Archivio') sectionFields.push( { id:6, value:'', title:'Email', name:'PersonEmail', type:'text', placeholder:'scrivi...', tabindex:0, disabled: true} );
      this.sections.push( {
        order: 1,
        title: 'Informazioni Fornitore',
        opened: true,
        fields: sectionFields 
      });
    },
    getDocu: function() {
      console.log('getDocu');
      var self=this;
      var soql='select '+this.docufieldsstring+' from Documento__c where caricato_da_BackOffice__c = true and Tipo_Documento_Lookup__r.Pubblicabile__c = true and Anagrafica__c=\''+this.recordId+'\'';
      console.log(soql);
      apiclient.query(this.$store.getters.connection, soql).then(function(data) {
        console.log('records:'+data.length);
        self.documenti=[];
        data.forEach(function(d){
          if(d.Stato_Pubblicazione__c == 'Si' && d.Avvia_Spubblicazione__c == false)d.Azione = 'Spubblica';
          if(d.Stato_Pubblicazione__c == 'No') d.Azione = 'Sollecita';
          d.link = 'https://unionefiduciaria--preproduct.lightning.force.com/servlet/servlet.FileDownload?file='+d.idFile__c;
          self.documenti.push(d);
        })
       // self.updateFieldsValue();
      }).catch(function(err){
        console.log(err);
      })
    },

    setupDocumento: function() {
      // Protocollo, fase, funzionario, assistente, CST, data apertura mandato
      var ord=1;
      this.docucols.push( { order: ord++, value:'', label:'Doc.', name:'Name', type:'string' } );
      this.docucols.push( { order: ord++, value:'', label:'Tipo', name:'Tipo_Documento_Lookup__r.Famiglia_Documento__c', type:'string' } );
      this.docucols.push( { order: ord++, value:'', label:'Pubblico', name:'Stato_Pubblicazione__c', type:'String' } );
      this.docucols.push( { order: ord++, value:'', label:'Soggetto', name:'AnRappLeg__r.tipoLegame_id__c', type:'string' } );
      this.docucols.push( { order: ord++, value:'', label:'Azione', name:'Azione', type:'Button' } );

      var mfields=new Map();
      mfields.set('Id', null);
      mfields.set('idFile__c', null);
      mfields.set('Avvia_Spubblicazione__c', null);
      this.mapFields(mfields, this.docucols);
      this.docufieldsstring=this.uniqueFields(mfields).join(',');
      this.docufieldsstring=this.docufieldsstring.replace(',Azione','');
    },
    getTimeline: function() {
      var self=this;
      var soql='select Id, Subject, ActivityDate, Description, Utente__c, Type from Task where whatId=\''+this.recordId+'\' '+this.$store.state.connection.userdata.Where_Task__c+' order by ActivityDate desc';
      console.log(soql);
      apiclient.query(this.$store.getters.connection, soql).then(function(data) {
        console.log('records:'+data.length);
        data.forEach(function(d) { d.show=false; });
        self.timeline=data;
      }).catch(function(err){
        console.log(err);
      })
    },
  }
}
</script>

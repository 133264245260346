<template>
  <div class="mandati">
    <gnetHeader></gnetHeader>

    <gnetTabBar
      v-bind:titoloapp="'Backoffice'"
      v-bind:tabs="$store.state.tabs"
      v-bind:active_tab="$store.state.active_tab"
      v-on:click="changeTab($event)"
    />
    <div class="slds-grid">
      <div class="slds-col slds-tabs_card slds-size_2-of-3" style="margin: 5px;">
        <div class="slds-card slds-card_boundary headerBottomBorder forceRelatedListCardDesktop">
        <div class="highlights slds-clearfix slds-page-header slds-page-header_record-home fixed-position">
        <gnetRecordHeader
          v-bind:object="'Case'"
          v-bind:title="record.Subject"
          v-bind:fields="headlineFields"
          v-bind:actions="headlineActions"
          v-on:click="actionClick($event)"
        />

        <article class="slds-card">
          <div class="slds-card__body slds-card__body_inner">

            <gnetFormMask v-on:click="toggleSection($event)" v-bind:sections="sections">

            </gnetFormMask>

          </div>
          <footer class="slds-card__footer">
          </footer>
        </article>

    <gnetModal
      v-if="newComment"
      v-bind:title="'Nuovo Commento: '+record.Subject"
      v-bind:object="'case'"
      v-on:cancel="newComment=false"
      v-on:submit="updateAccount"
    >
    </gnetModal>
        </div>
        </div>
        
      </div>
    </div>

    <gnetSpinner v-bind:busy="busy"></gnetSpinner>

  </div>
</template>

<script>
// @ is an alias to /src
// import numeral from 'numeral'
import gnetHeader from '../components/header.vue'
import gnetTabBar from '../components/tabbar.vue'
import gnetRecordHeader from '../components/recordheader.vue'
import gnetFormMask from '../components/formmask.vue'
import gnetSpinner from '../components/spinner.vue'
import apiclient from '@/apiclient.js'
import gnetMixLib from '@/mixin.js'
import gnetModal from '../components/modal.vue'

export default {
  name: 'Account',
  mixins: [gnetMixLib],
  components: {
    gnetHeader,
    gnetTabBar,
    gnetRecordHeader,
    gnetFormMask,
    gnetModal,
    gnetSpinner
  },
  data: function() {
    return {
      busy: false,
      recordId:'',
      record: {},

      newComment: false,
      fields:[],
      fieldsstring: '',
      headlineFields: [],
      headlineActions: [],
      sections:[],

      apporticols:[],
      apportifieldsstring:'',
      apporti:[],


    };
  },
  mounted: function() {
    this.setupHeaderFields();
    this.setupRecordFields();
    this.setupQueryFields();
    this.getRecord();
  },
  methods: {
    toggleSection: function(index) {
      this.sections[index].opened=!this.sections[index].opened;
    },
    getRecord: function() {
      var self=this;
      this.recordId=this.$route.params.id;
      var soql='select '+this.fieldsstring+' from Case where id=\''+this.recordId+'\'';
      console.log(soql);
      apiclient.query(this.$store.getters.connection, soql).then(function(data) {
        console.log('records:'+data.length);
        console.log(data)
        if (data.length>0)
          self.record=data[0];
        self.updateRecordFields();
      }).catch(function(err){
        console.log(err);
      })
    },
    updateRecordFields: function() {
      // header
      this.updateFieldsValue(this.headlineFields);
      // record
      this.sections.forEach(function(s) {
        this.updateFieldsValue(s.fields);
      }, this);

      // Modifica Tariffario;Inserire Parere;Effettuare Callback
      this.headlineActions=[];
      this.headlineActions.push( { name:'Nuovo Commento', class:'slds-button_neutral'  } );
    },
    setupQueryFields: function() {
      var mfields=new Map();

      // id e name sempre
      mfields.set('Id', null);
      
      // header
      this.mapFields(mfields, this.headlineFields);
      // record
      this.sections.forEach(function(s) {
        this.mapFields(mfields, s.fields);
      },this);
      // riassume
      this.fields=this.uniqueFields(mfields);
      this.fieldsstring=this.fields.join(',');
    },
    setupHeaderFields: function() {
      this.headlineFields.push( { value:'', label:'Stato', name:'Status', type:'String' } );
      this.headlineFields.push( { value:'', label:'Referente', name:'Contact.Name', type:'string' } );
      this.headlineFields.push( { value:'', label:'Email referente', name:'SuppliedEmail', type:'String' } );
      this.headlineFields.push( { value:'', label:'Data Creazione', name:'CreatedDate', type:'Date' } );

    },
    actionClick: function(actionName){
      if(actionName == 'Nuovo Commento') this.newComment = true;
    },
    insertCommento: function(){
      var self = this;
      this.comment.CaseId = this.recordId;
      apiclient.insert(this.$store.getters.connection, 'CaseComments', this.comment)
        .then(function(resp) {
            console.log(resp);
            self.newComment = false;
            self.getRecord();
            alert('Commento inserito!');
        })
        .catch(function(err) {
            console.log(err);
            alert('Errore nell\'inserimento: '+err);
        })
    },
    setupRecordFields: function() {
      var sectionFields=[];
      sectionFields.push( { id:1, value:'', title:'Oggetto', name:'Subject', type:'text', placeholder:'scrivi...', tabindex:0, disabled: true} );
      sectionFields.push( { id:2, value:'', title:'Stato', name:'Status', type:'text', placeholder:'scrivi...', tabindex:0, disabled: true} );
      sectionFields.push( { id:3, value:'', title:'Referente', name:'Contact.Name', type:'text', placeholder:'scrivi...', tabindex:0, disabled: true} );
      sectionFields.push( { id:4, value:'', title:'Email referente', name:'SuppliedEmail', type:'text', placeholder:'scrivi...', tabindex:0, disabled: true} );
      sectionFields.push( { id:5, value:'', title:'Data Creazione', name:'CreatedDate', type:'text' , placeholder:'scrivi...', tabindex:0, disabled: true} );
      this.sections.push( {
        order: 1,
        title: 'Dettagli',
        opened: true,
        fields: sectionFields 
      });
    }
  }
}
</script>

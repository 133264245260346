import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Mandati from '../views/Mandati.vue'
import Mandato from '../views/Mandato.vue'
import Apporto from '../views/Apporto.vue'
import Accounts from '../views/Accounts.vue'
import Account from '../views/Account.vue'
import Tasks from '../views/Tasks.vue'
import Task from '../views/Task.vue'
import Caso from '../views/Caso.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/mandati',
    name: 'Mandati',
    component: Mandati
  },
  {
    path: '/mandato/:id',
    name: 'Mandato',
    component: Mandato
  },
  {
    path: '/apporto/:id',
    name: 'Apporto',
    component: Apporto
  },
  {
    path: '/accounts',
    name: 'Accounts',
    component: Accounts
  },
  {
    path: '/account/:id',
    name: 'Account',
    component: Account
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: Tasks
  },
  {
    path: '/task/:id',
    name: 'Task',
    component: Task
  },
  {
    path: '/caso/:id',
    name: 'Caso',
    component: Caso
  }

]

const router = new VueRouter({
  routes
})

export default router

<template lang="html">

  <div class="slds-form" role="list">


    <gnetExpandable v-on:click="$emit('click',index)" v-for="(section,index) in sections" v-bind:key="section.order" v-bind:title="section.title" v-bind:opened='section.opened'>

      <span v-for="(f, index) in evenFields(section.fields)" v-bind:key="f.id">
        <div class="slds-form__row">

          <div class="slds-form__item" role="listitem">

            <gnetFormField v-bind:key="f.id" v-bind:title="f.title" v-bind:type="f.type" v-bind:name="f.name"
              v-bind:value="f.value" v-bind:placeholder="f.placeholder" v-bind:tabindex="f.tabindex"
              v-bind:disabled="f.disabled" />

          </div>

          <div class="slds-form__item" role="listitem">

            <gnetFormField v-if="nextFields(section.fields,index)" v-bind:key="nextFields(section.fields,index).id"
              v-bind:title="nextFields(section.fields,index).title" v-bind:type="nextFields(section.fields,index).type"
              v-bind:name="nextFields(section.fields,index).name" v-bind:value="nextFields(section.fields,index).value"
              v-bind:placeholder="nextFields(section.fields,index).placeholder"
              v-bind:tabindex="nextFields(section.fields,index).tabindex"
              v-bind:disabled="nextFields(section.fields,index).disabled" />
          </div>

        </div>
      </span>

    </gnetExpandable>



  </div>

</template>

<script>
import gnetFormField from '../components/formfield.vue'
import gnetExpandable from '../components/expandable.vue'

export default {
  name: 'gnetFormMask',
  components: {
    gnetFormField,
    gnetExpandable
  },
  props: {
    sections: Array,
  },
  data() {
    return {
    }
  },
  computed: {
  },
  async created() {
  },
  mounted: function() {
  },
  methods: {
    evenFields: function (fields) {
      return fields.filter(function (f,index) {
        return index % 2 === 0
      })
    },
    nextFields: function (fields, index) {
      var v=parseInt(index);
      var v1=((v)*2)+1;
      if (fields.length>v1)
        return fields[v1];
      return undefined;
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

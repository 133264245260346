/* eslint-disable no-console */

import axios from 'axios'
import jwt from 'jsonwebtoken'

const apiClient = axios.create({
  //  baseURL: `http://localhost:3000`,
  withCredentials: false, // This is the default
})

export default {
  secret: '',

  getKey(filename) {
    console.log('getkey:'+filename);

    this.secret='e2ffccce2a69190b8f3f16896b2cadeae380caaaae7e7fac2f31a83bd55757c5';

    return axios.get(filename);
  },
  query(con, soql) {
    var self=this;
    return new Promise(function(resolve,reject) {
      console.log('manda la query');
      var url = "./query"; // state.connection.mytoken
      var payload = {
        token: con.mytoken,
        query: soql
      }
      // console.log(payload);

      var jwtpayload = jwt.sign( payload, self.secret ); // con.key, { algorithm: 'RS256' });

      axios.post(url, { payload: jwtpayload } ).then(function(resp) {
        console.log('ricevuto');
        var data = resp.data;
        if (data.records) {
          resolve(data.records);
        }
      }).catch(function(err) {
        console.log('problemi');
        reject(err);
      });
        
    });
  },

  update(con, object, id, body) {
    var self=this;
    return new Promise(function(resolve,reject) {
      console.log('manda un update');
      var url = "./update"; 
      var payload = {
        token: con.mytoken,
        payload: { object: object, id: id, body: body }
      }
      // console.log(payload);

      var jwtpayload = jwt.sign( payload, self.secret ); // con.key, { algorithm: 'RS256' });

      axios.post(url,  { payload: jwtpayload }  ).then(function(resp) {
        console.log('ricevuto');
        console.log(resp);
        resolve(resp);
      }).catch(function(err) {
        console.log('problemi');
        reject(err);
      });
        
    });
  },
  insert(con, object, body) {
    var self=this;
    return new Promise(function(resolve,reject) {
      console.log('manda un insert');
      var url = "./insert"; 
      var payload = {
        token: con.mytoken,
        payload: { object: object, body: body }
      }
      // console.log(payload);

      var jwtpayload = jwt.sign( payload, self.secret ); // con.key, { algorithm: 'RS256' });

      axios.post(url,  { payload: jwtpayload }  ).then(function(resp) {
        console.log('ricevuto');
        console.log(resp);
        resolve(resp);
      }).catch(function(err) {
        console.log('problemi');
        reject(err);
      });
        
    });
  },

  newTask(con, body) {
    var self=this;
    return new Promise(function(resolve,reject) {
      console.log('manda un task');
      var url = "./task"; 
      var payload = {
        token: con.mytoken,
        payload: body
      }
      // console.log(payload);

      var jwtpayload = jwt.sign( payload, self.secret ); // con.key, { algorithm: 'RS256' });

      axios.post(url,  { payload: jwtpayload }  ).then(function(resp) {
        console.log('ricevuto');
        console.log(resp);
        resolve(resp);
      }).catch(function(err) {
        console.log('problemi');
        reject(err);
      });
        
    });
  },
  
  login(con /* id, id_token */ ) {
    return new Promise(function(resolve, reject) {
      var mytokenizedid = jwt.sign({
        email: con.email,  // id google
        id_token: con.myid_token // token google
      }, con.key, {
        expiresIn: "10s",
        algorithm: 'RS256'        
      });
      apiClient.post('/me', {
          token: mytokenizedid // jwt del mio payload
        })
        .then(function(resp) {
          var data = resp.data;
          console.log('miei dati');
          console.log(data);
          var o = {
            mytokenizedid: mytokenizedid, // jwt del mio payload
            mytoken: data.token, // token del server
            userdata: data.userdata // dati utente
          }
          con.commit('SET_CONNECTION_TOKEN', o);
          resolve(o);
        })
        .catch(function(err) {
          console.log(err);
          reject(err);
        })
    })
  },
  getdata(con, body) {
    var self=this;
    console.log(body)
    return new Promise(function(resolve,reject) {
      console.log('manda getdata');
      var url = "./getdata"; // state.connection.mytoken
      var payload = {
        token: con.mytoken,
        payload: body
      }
      // console.log(payload);

      var jwtpayload = jwt.sign( payload, self.secret ); // con.key, { algorithm: 'RS256' });

      axios.post(url,  { payload: jwtpayload }  ).then(function(resp) {
        console.log('ricevuto');
        console.log(resp);
        resolve(resp);
      }).catch(function(err) {
        console.log('problemi');
        reject(err);
      });
        
    });
  },
  /*
  getEvents() {
    return apiClient.get('/events')
  },
  checklogin(con) {
    console.log('checklogin:')
    var self = this;
    return new Promise(function(resolve, reject) {
      jwt.verify(con.mytokenizedid, self.secret, function(err, decoded) {
        if (err) { // do relogin
          console.log('do relogin:')
          self.login(con.email, con.myid_token).then(function(data) {
            console.log('relogin ok:' + data);
            con.commit('SET_CONNECTION_TOKEN', data);
            resolve(data);
          }).catch(function(err) {
            reject(err);
          })
        } else
          decoded; // lint
          var o = {
            mytokenizedid: con.mytokenizedid, // jwt del mio payload
            mytoken: con.token
          }
        resolve(o); // sostanzialmente inutile
      });
    })
  },
  getTasks(con, id) {
    var self = this;
    return new Promise(function(resolve, reject) {
      self.checklogin(con).then(function() {
        var url = "./query?q=";
        var soql = "SELECT  Id, Subject, Description, CreatedDate FROM Task where WhatId='" + id + "' order by CreatedDate desc";
        console.log(soql);
        axios.get(url + soql).then(function(resp) {
          var data = resp.data;
          resolve(data);
        }).catch(function(err) {
          reject(err);
        });

      }).catch(function(err) {
        reject(err);
      });

    });
  }
  */
}

<template lang="html">

    <div class="slds-form-element slds-form-element_stacked" v-bind:class="{ 'slds-form-element_readonly': disabled}">
      
      <template v-if="disabled">
        <span class="slds-form-element__label">{{title}}</span>
          <div class="slds-form-element__control">
            <div class="slds-form-element__static">
              <span v-if="type=='text' || type=='picklist' " v-html="value"></span>
              <span v-if="type=='number' || type=='currency' || type=='date' || type=='datetime'">{{value | filter(type)}}</span>
            </div>
          </div>
      </template>

      <template v-if="!disabled">

        <label v-if=" type!='checkbox' && type!='picklist' " class="slds-form-element__label" v-bind:for="'form-element-'+tabindex">{{title}}</label>

        <div v-if="type=='text' || type=='number' || type=='picklist' " class="slds-form-element__control">
          <input v-on:change="$emit('change',$event.target.value)" v-bind:value="value" v-bind:type="type" v-bind:id="'form-element-'+tabindex" v-bind:placeholder="placeholder" class="slds-input" />
        </div>

        <div v-if="type=='textarea' || type=='number' " class="slds-form-element__control">
          <textarea v-on:change="$emit('change',$event.target.value)" v-bind:value="value" v-bind:type="type" v-bind:id="'form-element-'+tabindex" v-bind:placeholder="placeholder" class="slds-input" />
        </div>

        <div v-if="type=='checkbox' " >
          <fieldset class="slds-form-element slds-form-element_horizontal">
              <legend class="slds-form-element__legend slds-form-element__label">Promemoria</legend>
              <div class="slds-form-element__control">
                <div v-on:click="$emit('change', !value)" class="slds-checkbox">
                    <input v-bind:value="value" v-bind:checked="value" v-bind:type="type" v-bind:id="'form-element-'+tabindex"  />
                    <label class="slds-checkbox__label" for="checkbox-103">
                        <span class="slds-checkbox_faux"></span>
                        <span class="slds-form-element__label">{{title}}</span>
                    </label>
                </div>
              </div>
          </fieldset>
        </div>
<!--
        <div v-if="type=='picklist' " >
          <formpicklist
            v-bind:id="id"
            v-bind:title="title"
            v-bind:type="type"
            v-bind:name="name"
            v-bind:value="value"
            v-bind:placeholder="placeholder"
            v-bind:tabindex="tabindex"
            v-bind:disabled="disabled"
            v-bind:options="options"
            v-on:change="$emit('change', $event)"
            />
        </div>
-->
        <div v-if="type=='date' " class="slds-form-element__control">
          <input v-on:change="$emit(value)" v-bind:value="value" v-bind:type="type" v-bind:id="'form-element-'+tabindex" v-bind:placeholder="placeholder" class="slds-input" />
        </div>

        <div v-if="type=='datetime' " class="slds-form-element__control">
          <VueCtkDateTimePicker v-model="value" v-bind:no-header="true" v-bind:overlay="false" v-on:change="$emit('change', $event)" />

<!--          <input v-bind:value="value" v-bind:type="type" v-bind:id="'form-element-'+tabindex" v-bind:placeholder="placeholder" class="slds-input" /> -->
        </div>
      </template>
    </div>

</template>

<script>
// import formpicklist from './formpicklist.vue'
// import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
// import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

import numeral from 'numeral'
import moment from 'moment'

  export default {
    name: 'gnetFormField',
    components: {
//      formpicklist,
//      VueCtkDateTimePicker
     },
    props: {
      id: String,
      name: String,
      value: [String, Number, Date, Boolean],
      title: String,
      type: String,
      tabindex: Number,
      disabled: Boolean,
      placeholder: String,
      options: Array
    },
    filters: {
      filter: function(v, type) {
        switch(type) {
          case 'date':
            var n=new moment(v);
            return n.format('DD/MM/YYYY');
          case 'currency':
            return numeral(v).format('$ 0,0.00');
          case 'alert':
              return v;
        }
        return v;
      }
    },
    data() {
      return {}
    },
    computed: {},
    async created() {},
    methods: {}
  }

</script>

<style scoped>

</style>

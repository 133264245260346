<template>
  <div class="mandati">
    <gnetHeader></gnetHeader>

    <gnetTabBar
      v-bind:titoloapp="'Backoffice'"
      v-bind:tabs="$store.state.tabs"
      v-bind:active_tab="$store.state.active_tab"
      v-on:click="changeTab($event)"
    />

    <div class="slds-grid" style="overflow: auto;">
      <div class="slds-col slds-tabs_card slds-size_2-of-3" style="margin: 5px;">
        <div class="slds-card slds-card_boundary headerBottomBorder forceRelatedListCardDesktop">
        <div class="highlights slds-clearfix slds-page-header slds-page-header_record-home fixed-position">
        <gnetRecordHeader
          v-bind:object="'opportunity'"
          v-bind:title="'Mandato '+record.AnRapp_id__c"
          v-bind:subtitle="record.TbOggRapp_oggRapporto_id__c"
          
          v-bind:fields="headlineFields"
          v-bind:actions="headlineActions"
          v-on:click="actionClick($event)"
        />
        </div>
        <article class="slds-card">
          <div class="slds-card__body slds-card__body_inner">

            <gnetFormMask v-on:click="toggleSection($event)" v-bind:sections="sections">

            </gnetFormMask>

          </div>
          <footer class="slds-card__footer">
          </footer>
        </article>
        </div>
        <div v-if="$store.state.connection.userdata.Visibilit_apporto_su_opp__c !== undefined && $store.state.connection.userdata.Visibilit_apporto_su_opp__c == true" class="slds-card slds-card_boundary headerBottomBorder forceRelatedListCardDesktop">
        <gnetRecordHeader
          v-bind:object="'account'"
          v-bind:title="'Apporti'"
        />
        <gnetTable
          v-bind:cols="apporticols"
          v-bind:records="apporti"
          v-on:click="gotoApporto($event)"
        >      
        </gnetTable>
        </div>
        <div v-if="$store.state.connection.userdata.Visibilit_soggetto_su_opp__c !== undefined && $store.state.connection.userdata.Visibilit_soggetto_su_opp__c == true" class="slds-card slds-card_boundary headerBottomBorder forceRelatedListCardDesktop">
        <gnetRecordHeader
          v-bind:object="'account'"
          v-bind:title="'Soggetto Legame'"
        />
        <gnetTable
          v-bind:cols="sogcols"
          v-bind:records="sogs"
          v-bind:nolink="true"
        >      
        </gnetTable>
        </div>
        <div v-if="$store.state.connection.userdata.Visibilit_documenti_su_opp__c !== undefined && $store.state.connection.userdata.Visibilit_documenti_su_opp__c == true" class="slds-card slds-card_boundary headerBottomBorder forceRelatedListCardDesktop">
        <gnetRecordHeader
          v-bind:object="'document'"
          v-bind:title="'Documenti'"
        />
        <gnetTable
          v-bind:cols="docucols"
          v-bind:records="documenti"
          v-on:azione="upDoc($event)"
          v-bind:linkspecial="true"
        >      
        </gnetTable>
        </div>
        <!--div v-if="cases.length > 0" class="slds-card slds-card_boundary headerBottomBorder forceRelatedListCardDesktop">
        <gnetRecordHeader
          v-bind:object="'case'"
          v-bind:title="'Casi'"
        />
        <gnetTable
          v-bind:cols="casecols"
          v-bind:records="cases"
          v-on:click="gotoCaso($event)"
        >      
        </gnetTable>
        </div-->
      </div>
      <div class="slds-col">
      <div class="slds-tabs_card" style="margin: 5px;">
        <div class="slds-card slds-card_boundary headerBottomBorder forceRelatedListCardDesktop">
        <gnetTimeline v-bind:records="timeline" v-bind:currentRecord="record" v-on:finish="getTimeline()">
        </gnetTimeline>
        </div>
      </div>

      <div class="slds-tabs_card" style="margin: 5px;" v-if="$store.state.connection.userdata.Permesso_upload_doc_su_opp__c !== undefined && $store.state.connection.userdata.Permesso_upload_doc_su_opp__c == true">
        <div class="slds-card slds-card_boundary forceBaseCard flexipageCard" style="margin-top: 15px">
        <uploadDocs v-on:click="getDocu" v-bind:utentePort="$store.state.connection.userdata.Id" v-bind:related="'Opportunity'" v-bind:con="$store.state.connection" v-bind:rId="record.Id" v-bind:rec="record" v-bind:ent="'Opportunity'">
        </uploadDocs>
        </div>

      </div>
      </div>


    </div>

    <gnetModal 
      v-if="showParere" 
      v-bind:title="'Inserisci Parere'"
      v-on:cancel="showParere=false"
      v-on:submit="creaParere"
      v-bind:hspace="'small'"
    >

      <gnetPicklist
        v-bind:title="'Sintesi Parere'"
        v-bind:options="esiti"
        v-bind:value="esito"
        v-on:change="esito=$event"
      />
      <gnetFormField v-bind:title="'Commento'" v-bind:type="'textarea'"
        v-bind:value="esitoNote" v-bind:placeholder="'Note...'" v-on:change="esitoNote=$event"
      />

    </gnetModal>

    <gnetModal 
      v-if="showCommento" 
      v-bind:title="'Inserisci Commento'"
      v-on:cancel="showCommento=false"
      v-on:submit="creaCommento"
      v-bind:hspace="'small'"
    >

      <gnetPicklist
        v-bind:title="'Sintesi Commento'"
        v-bind:options="esiti"
        v-bind:value="esito"
        v-on:change="esito=$event"
      />
      <gnetFormField v-bind:title="'Commento'" v-bind:type="'textarea'"
        v-bind:value="esitoNote" v-bind:placeholder="'Note...'" v-on:change="esitoNote=$event"
      />

    </gnetModal>
    <div v-if="$store.state.connection.userdata.Visibilit_tariffario_su_opp__c !== undefined && $store.state.connection.userdata.Visibilit_tariffario_su_opp__c == true">
      <gnetModal 
        v-if="showTariffario" 
        v-bind:title="'Modifica Tariffario'"
        v-on:cancel="showTariffario=false"
        v-on:submit="variaTariffario"
        v-bind:hspace="'small'"
      >
        <gnetLookup 
          v-bind:label="'Tariffario'"
          v-bind:object="'currency'"
          v-bind:selected="tariffario"
          v-bind:founded="tariffari"
          v-on:change="cercaTariffario($event)"
          v-on:click="selezionaTariffario($event)"
          v-on:selected="tariffario"
        />
        <gnetFormField v-bind:title="'Commento'" v-bind:type="'textarea'"
          v-bind:value="tariffarioNote" v-bind:placeholder="'Note...'" v-on:change="tariffarioNote=$event"
        />
        
      </gnetModal>

    </div>
    <gnetSpinner v-bind:busy="busy"></gnetSpinner>

  </div>
</template>

<script>
// @ is an alias to /src
// import numeral from 'numeral'
import gnetHeader from '../components/header.vue'
import gnetTabBar from '../components/tabbar.vue'
import gnetRecordHeader from '../components/recordheader.vue'
import gnetFormMask from '../components/formmask.vue'
import gnetTimeline from '../components/timeline.vue'
import gnetModal from '../components/modal.vue'
import gnetTable from '../components/table.vue'
import gnetLookup from '../components/lookup.vue'
import gnetPicklist from '../components/picklist.vue'
import gnetFormField from '../components/formfield.vue'
import gnetSpinner from '../components/spinner.vue'
import uploadDocs from '../components/uploadDocs.vue'
import apiclient from '@/apiclient.js'
import gnetMixLib from '@/mixin.js'

export default {
  name: 'Mandato',
  mixins: [gnetMixLib],
  components: {
    gnetHeader,
    gnetTabBar,
    gnetRecordHeader,
    gnetFormMask,
    gnetTimeline,
    gnetModal,
    gnetTable,
    gnetLookup,
    gnetPicklist,
    gnetFormField,
    gnetSpinner,
    uploadDocs
  },
  data: function() {
    return {
      busy: false,
      recordId:'',
      record: {},

      fields:[],
      fieldsstring: '',
      headlineFields: [],
      headlineActions: [],
      sections:[],

      apporticols:[],
      apportifieldsstring:'',
      apporti:[],

      docucols:[],
      docufieldsstring: '',
      documenti:[],

      sogcols:[],
      sogfieldsstring: '',
      sogs:[],

      casecols:[],
      casefieldsstring: '',
      cases:[],

      timeline: [],

      // pareri
      showCommento: false,
      showParere: false,
      esiti: [
        { label:'Positivo', value:'Positivo'},
        { label:'Negativo', value:'Negativo'},
      ],
      esito:'',
      esitoNote:'',

      // tariffari
      showTariffario: false,
      tariffari: [],
      tariffario:'',
      tariffarioNote:'',

    };
  },
  mounted: function() {
    console.log(this.$store.state.connection);
    this.setupHeaderFields();
    this.setupRecordFields();
    this.setupQueryFields();
    this.setupApporti();
    this.setupSog();
    this.setupDocumento();
    this.setupCase();
    this.getRecord();
    this.getApporti();
    this.getSogLeg();
    this.getDocu();
    this.getCases();
    this.getTimeline();
  },
  methods: {
    variaTariffario: function() {
      console.log('variaTariffario');

      var body={ Tariffario_Proposto_Backoffice__c: this.tariffario };
      var self=this;
      self.busy=true;
      apiclient.update(this.$store.getters.connection, 'Opportunity', this.record.Id, body).then(function(resp) {
        console.log(resp);

        var body={ WhatId: self.record.Id, Subject: 'Richiesta Variazione Tariffario', Type: 'Variazione Tariffario', Description: self.tariffarioNote, Utente__c: self.$store.state.user.email, Status: 'Completata', ActivityDate: new Date() };
        apiclient.newTask(self.$store.getters.connection, body).then(function(resp) {
          console.log(resp);
          self.getRecord(); // refresh mandato
          self.getTimeline();
          self.showTariffario=false;
          self.busy=false;
        }).catch(function(err) {
          console.log(err);
          self.showTariffario=false;
          self.busy=false;
        })

      }).catch(function(err) {
        console.log(err);
        self.showTariffario=false;
        self.busy=false;
      })

/*
      var body={ WhatId: this.record.Id, Subject: 'Richiesta Variazione Tariffario', Type: 'Variazione Tariffario', Description: this.tariffarioNote, Utente__c: this.$store.state.user.email, Status: 'Completata', ActivityDate: new Date() };
      var self=this;
      apiclient.newTask(this.$store.getters.connection, body).then(function(resp) {
        console.log(resp);
        self.getRecord(); // refresh mandato
        self.getTimeline();
        self.showTariffario=false;
      }).catch(function(err) {
        console.log(err);
        self.showTariffario=false;
      })
*/
    },
    selezionaTariffario: function(scelta) {
      console.log(scelta);
      this.tariffario=this.tariffari[scelta].value;
    },
    cercaTariffario: function(cerca) {
      var self=this;
      var soql='select Id,Name from Tariffario__c where Name like \''+cerca+'%\'';
      console.log(soql);
      apiclient.query(this.$store.getters.connection, soql).then(function(data) {
        console.log(data);
        var t=[];
        data.forEach(function(d) {
          t.push( { value:d.Id, name:d.Name});
        });
        self.tariffari=t;
      }).catch(function(err){
        console.log(err);
      })
    },
    /*gotoApporto: function(record) {
      console.log(record);
      if(record)this.$router.push({ name:'Apporto', params: { id: record.Id } });
    },*/
    gotoCaso: function(record) {
      console.log(record);
      if(record)this.$router.push({ name:'Caso', params: { id: record.Id } });
    },
    creaParere: function() {
      this.record.Backoffice_Alert__c = this.removeAlert(this.record.Backoffice_Alert__c, 'Richiesto Parere');
      this.updateRecordFields(); // aggiorna la maschera

      var body={ Backoffice_Alert__c: this.record.Backoffice_Alert__c, Stato_Parere_Economics__c: 'Parere '+this.esito };
      var self=this;
      self.busy=true;
      apiclient.update(this.$store.getters.connection, 'Opportunity', this.record.Id, body).then(function() {
        var body={ WhatId: self.record.Id, Subject: 'Esito Parere:'+self.esito, Type: 'Parere', Description: self.esitoNote, Utente__c: self.$store.state.user.email, Status: 'Completata', ActivityDate: new Date() };
        return apiclient.newTask(self.$store.getters.connection, body);
      }).then(function() {
        self.getTimeline();
        self.showParere=false;
        self.busy=false;
      }).catch(function(err) {
        console.log(err);
        self.showParere=false;
        self.busy=false;
      })
    },
    removeAlert: function(fields, alert) {
      if (!fields) return fields;
      var a=fields.split(';');
      var b=[];
      a.forEach(function(v){
        if (alert!=v)
          b.push(v);
      });
      return b.join(';');
    },
    creaCommento: function() {
      this.record.Backoffice_Alert__c = this.removeAlert(this.record.Backoffice_Alert__c, 'Variazione Scontistica');
      this.updateRecordFields(); // aggiorna la maschera

      var body={ Backoffice_Alert__c: this.record.Backoffice_Alert__c };
      var self=this;
      self.busy=true;
      apiclient.update(this.$store.getters.connection, 'Opportunity', this.record.Id, body).then(function() {
        var body={ WhatId: self.record.Id, Subject: 'Commento Variazione:'+self.esito, Type: 'Commento Variazione', Description: self.esitoNote, Utente__c: self.$store.state.user.email, Status: 'Completata', ActivityDate: new Date() };
        return apiclient.newTask(self.$store.getters.connection, body);
      }).then(function() {
        self.getTimeline();
        self.showCommento=false;
        self.busy=false;
      }).catch(function(err) {
        console.log(err);
        self.showCommento=false;
        self.busy=false;
      });
    },
    toggleSection: function(index) {
      this.sections[index].opened=!this.sections[index].opened;
    },
    actionClick: function(actionName) {
      console.log('action!'+actionName);
      if (actionName=='Inserisci Parere') {
        this.showParere=true;
      }
      if (actionName=='Commenta variazione') {
        this.showCommento=true;
      }
      if (actionName=='Modifica Tariffario') {
        this.showTariffario=true;
      }
    },
    getRecord: function() {
      var self=this;
      this.recordId=this.$route.params.id;
      var soql='select '+this.fieldsstring+' from Opportunity where id=\''+this.recordId+'\'';
      console.log(soql);
      apiclient.query(this.$store.getters.connection, soql).then(function(data) {
        console.log('records:'+data.length);
        console.log(data)
        if (data.length>0)
          self.record=data[0];
        self.updateRecordFields();
      }).catch(function(err){
        console.log(err);
      })
    },
    getApporti: function() {
      var self=this;
      var soql='select '+this.apportifieldsstring+' from Apporto__c where Opportunita__c=\''+this.recordId+'\'';
      console.log(soql);
      apiclient.query(this.$store.getters.connection, soql).then(function(data) {
        console.log('records:'+data.length);
        self.apporti=data;
       // self.updateFieldsValue();
      }).catch(function(err){
        console.log(err);
      })
    },
    getSogLeg: function() {
      var self=this;
      var soql='select '+this.sogfieldsstring+' from AnRappLeg__c where idCodRapp__c=\''+this.recordId+'\'';
      console.log(soql);
      apiclient.query(this.$store.getters.connection, soql).then(function(data) {
        console.log('sog:'+data.length);
        self.sogs=data;
        console.log(data);
      }).catch(function(err){
        console.log(err);
      })
    },
    getDocu: function() {
      console.log('getDocu');
      var self=this;
      var soql='select '+this.docufieldsstring+', (select id, name, Soggetto_Legame__r.Persona_fisica_giuridica__r.idCodCliente__c, toLabel(Soggetto_Legame__r.tipoLegame_id__c) from Documento_Legami__r) from Documento__c where caricato_da_BackOffice__c = true and Tipo_Documento_Lookup__r.Pubblicabile__c = true and Opportunita__c=\''+this.recordId+'\' order by CreatedDate';
      console.log(soql);
      apiclient.query(this.$store.getters.connection, soql).then(function(data) {
        console.log('records:'+data.length);
        self.documenti=[];
        data.forEach(function(d){
          if(d.Stato_Pubblicazione__c == 'Si' && d.Avvia_Spubblicazione__c == false)d.Azione = 'Spubblica';
          if(d.Stato_Pubblicazione__c == 'No') d.Azione = 'Sollecita';
          if(d.Documento_Legami__r) d.Soggetto = d.Documento_Legami__r.records[0].Soggetto_Legame__r.tipoLegame_id__c;
          d.idFile = d.idFile__c;
          self.documenti.push(d);
        })
       // self.updateFieldsValue();
      }).catch(function(err){
        console.log(err);
      })
    },
    getCases: function() {
      var self=this;
      var soql='select '+this.casefieldsstring+' from Case where Rapporto__c=\''+this.recordId+'\'';
      console.log(soql);
      apiclient.query(this.$store.getters.connection, soql).then(function(data) {
        console.log('records:'+data.length);
        self.cases=data;
       // self.updateFieldsValue();
      }).catch(function(err){
        console.log(err);
      })
    },
    upDoc: function(evt){
      var self=this;
      var azione = evt[0];
      var r = evt[1];
       var doc = {};
      if(azione == 'spub') doc = {Avvia_Spubblicazione__c: true, caricato_da_BackOffice__c: true, Utente_Portale_Spubblica__c: this.$store.state.connection.userdata.Id};
      if(azione == 'soll') doc = {Sollecito_Pubblicazione__c: true};
      if(azione == 'elim') doc = {Elimina_record__c: true};
      apiclient.update(this.$store.getters.connection, 'Documento__c', r.Id, doc)
      .then(function(resp) {
          console.log(resp);
          self.getDocu();
          if(azione == 'spub')alert('Documento inviato per la rimozione della pubblicazione!');
          if(azione == 'soll')alert('Sollecito inviato!');
          if(azione == 'elim')alert('Documento eliminato!');
      })
      .catch(function(err) {
          console.log(err);
          alert('Errore nell\'aggiornamento: '+err);
      })
    },
    updateRecordFields: function() {
      // header
      this.updateFieldsValue(this.headlineFields);
      // record
      this.sections.forEach(function(s) {
        this.updateFieldsValue(s.fields);
      }, this);

      // Modifica Tariffario;Inserire Parere;Effettuare Callback
      this.headlineActions=[];
      if (this.$store.state.connection.userdata.Name == 'Ufficio Commissioni' && this.record.Necessario_Parere__c) { // inizio flusso approvazione
          this.headlineActions.push( { name:'Inserisci Parere', symbol:'alert', class:'slds-button_destructive' } );
      }
      if (this.$store.state.connection.userdata.Name == 'Ufficio Commissioni' && this.record.StageName == 'Mandato Accettato') { // se non c'e' un flusso in o
          this.headlineActions.push( { name:'Commenta variazione',  } );
          this.headlineActions.push( { name:'Modifica Tariffario',  } );
      }
      
    },
    setupQueryFields: function() {
      var mfields=new Map();

      // id e name sempre
      mfields.set('Id', null);
      mfields.set('Name', null);
      mfields.set('AnRapp_id__c', null);
      mfields.set('Necessario_Parere__c', null);
      mfields.set('Verifica_in_corso__c', null);
      
      // header
      this.mapFields(mfields, this.headlineFields);
      // record
      this.sections.forEach(function(s) {
        this.mapFields(mfields, s.fields);
      },this);
      // riassume
      this.fields=this.uniqueFields(mfields);
      this.fieldsstring=this.fields.join(',');
    },
    setupHeaderFields: function() {
      this.headlineFields.push( { value:'', label:'Codice Cliente', name:'Account.idCodCliente__c' } );
      this.headlineFields.push( { value:'', label:'Fase', name:'StageName', type:'picklist'} );
      this.headlineFields.push( { value:'', label:'Funzionario', name:'Owner.Name' } );
      this.headlineFields.push( { value:'', label:'Alert', name:'Backoffice_Alert__c', type:'alert' } );

    },
    setupRecordFields: function() {
      var sectionFields=[];
      if(this.$store.state.connection.userdata.Visibilit_tariffario_su_opp__c == true || (this.record.Verifica_in_corso__c == true && this.$store.state.connection.userdata.Name == 'Ufficio Disposizioni')){
      sectionFields.push( { id:1, value:'', title:'Tipo di contratto', name:'TbTipMand_tipoMandato_id__c', type:'picklist', placeholder:'scrivi...', tabindex:0, disabled: true} );
      sectionFields.push( { id:2, value:'', title:'Oggetto', name:'TbOggRapp_oggRapporto_id__c', type:'picklist', placeholder:'scrivi...', tabindex:0, disabled: true} );
      sectionFields.push( { id:3, value:'', title:'Regime del rapporto', name:'regimerapp__c', type:'picklist', placeholder:'scrivi...', tabindex:0, disabled: true} );
      sectionFields.push( { id:4, value:'', title:'Prodotto', name:'prodotto__c', type:'picklist', placeholder:'scrivi...', tabindex:0, disabled: true} );
      sectionFields.push( { id:5, value:'', title:'Convenzione', name:'Convenzione__c', type:'picklist', placeholder:'scrivi...', tabindex:0, disabled: true} );
      sectionFields.push( { id:6, value:'', title:'Tariffario', name:'Tariffario__r.Name', type:'text', placeholder:'scrivi...', tabindex:0, disabled: true} );
      this.sections.push( {
        order: 1,
        title: 'TARIFFARIO',
        opened: true,
        fields: sectionFields 
      });
      }
      if(this.$store.state.connection.userdata.Visibilit_beni_su_opp__c == true){
        sectionFields=[];
        sectionFields.push( { id:1, value:'', title:'Amount', name:'Amount', type:'decimal', placeholder:'scrivi...', tabindex:0, disabled: true} );
        sectionFields.push( { id:2, value:'', title:'Sconto % su commissioni periodiche', name:'PercDerogaComm__c', type:'percentage', placeholder:'scrivi...', tabindex:0, disabled: true} );
        sectionFields.push( { id:3, value:'', title:'Commissioni forfettarie periodiche', name:'Spese_forfettarie__c', type:'decimal', placeholder:'scrivi...', tabindex:0, disabled: true} );
        sectionFields.push( { id:4, value:'', title:'Sconto % su commissioni apertura mandato', name:'Sconto_comm_am__c', type:'text', placeholder:'scrivi...', tabindex:0, disabled: true} );
        sectionFields.push( { id:5, value:'', title:'Approvazione sconto', name:'Approvazione_sconto__c', type:'text', placeholder:'scrivi...', tabindex:0, disabled: true} );
        sectionFields.push( { id:6, value:'', title:'Mezzi di Pagamento', name:'uicCodMPag1_id_TbUicMPag__c', type:'picklist', placeholder:'scrivi...', tabindex:0, disabled: true} );
        sectionFields.push( { id:7, value:'', title:'Soggetto terzo pagatore', name:'clienteTerzoPag_id2__c', type:'text', placeholder:'scrivi...', tabindex:0, disabled: true} );
        this.sections.push( {
          order: 2,
          title: 'ECONOMICS',
          opened: true,
          fields: sectionFields 
        });
      }
    },
    getTimeline: function() {
      var self=this;
      var soql='select Id, Subject, ActivityDate, Description, Utente__c, Type from Task where whatId=\''+this.recordId+'\' '+this.$store.state.connection.userdata.Where_Task__c+' order by ActivityDate desc';
      console.log(soql);
      apiclient.query(this.$store.getters.connection, soql).then(function(data) {
        console.log('records:'+data.length);
        data.forEach(function(d) { d.show=false; });
        self.timeline=data;
      }).catch(function(err){
        console.log(err);
      })
    },
    setupApporti: function() {
      // Protocollo, fase, funzionario, assistente, CST, data apertura mandato
      var ord=1;
      this.apporticols.push( { order: ord++, value:'', label:'Prot.', name:'Name', type:'string' } );
      this.apporticols.push( { order: ord++, value:'', label:'Tipo', name:'Tipo_apporto__c', type:'string' } );
      this.apporticols.push( { order: ord++, value:'', label:'Controvalore', name:'Controvalore_apporto__c', type:'currency' } );
      this.apporticols.push( { order: ord++, value:'', label:'Stato Approvazione', name:'Status_approvazione__c', type:'string' } );
      this.apporticols.push( { order: ord++, value:'', label:'Stato Callback', name:'Stato_Callback__c', type:'alert' } );

      var mfields=new Map();
      mfields.set('Id', null);
      this.mapFields(mfields, this.apporticols);
      this.apportifieldsstring=this.uniqueFields(mfields).join(',');
    },
    setupDocumento: function() {
      // Protocollo, fase, funzionario, assistente, CST, data apertura mandato
      var ord=1;
      this.docucols.push( { order: ord++, value:'', label:'Doc.', name:'Name', type:'string' } );
      this.docucols.push( { order: ord++, value:'', label:'Tipo', name:'Tipo_Documento_Lookup__r.Name', type:'string' } );
      this.docucols.push( { order: ord++, value:'', label:'Caricato da', name:'Utente_Portale_Created__r.Name', type:'string' } );
      this.docucols.push( { order: ord++, value:'', label:'Pubblico', name:'Stato_Pubblicazione__c', type:'String' } );
      this.docucols.push( { order: ord++, value:'', label:'Soggetto', name:'Soggetto', type:'string' } );
      this.docucols.push( { order: ord++, value:'', label:'Azione', name:'Azione', type:'Button' } );

      var mfields=new Map();
      mfields.set('Id', null);
      mfields.set('idFile__c', null);
      mfields.set('Avvia_Spubblicazione__c', null);
      this.mapFields(mfields, this.docucols);
      this.docufieldsstring=this.uniqueFields(mfields).join(',');
      this.docufieldsstring=this.docufieldsstring.replace(',Azione','');
      this.docufieldsstring=this.docufieldsstring.replace(',Soggetto','');
    },
    setupSog: function() {
      // Protocollo, fase, funzionario, assistente, CST, data apertura mandato
      var ord=1;
      this.sogcols.push( { order: ord++, value:'', label:'Codice Cliente', name:'ID_Codice_Cliente_Soggetto_Legame__c', type:'string' } );
      this.sogcols.push( { order: ord++, value:'', label:'Ruolo', name:'tipoLegame_id__c', type:'string' } );

      var mfields=new Map();
      mfields.set('Id', null);
      mfields.set('toLabel(tipoLegame_id__c)', null)
      this.mapFields(mfields, this.sogcols);
      this.sogfieldsstring=this.uniqueFields(mfields).join(',');
      this.sogfieldsstring=this.sogfieldsstring.replace(',tipoLegame_id__c','');
    },
    setupCase: function() {
      // Protocollo, fase, funzionario, assistente, CST, data apertura mandato
      var ord=1;
      this.casecols.push( { order: ord++, value:'', label:'Oggetto', name:'Subject', type:'string' } );
      this.casecols.push( { order: ord++, value:'', label:'Stato', name:'Status', type:'String' } );
      this.casecols.push( { order: ord++, value:'', label:'Referente', name:'Contact.Name', type:'string' } );
      this.casecols.push( { order: ord++, value:'', label:'Email referente', name:'SuppliedEmail', type:'String' } );
      this.casecols.push( { order: ord++, value:'', label:'Data Creazione', name:'CreatedDate', type:'Date' } );

      var mfields=new Map();
      mfields.set('Id', null);
      mfields.set('Rapporto__c', null);
      this.mapFields(mfields, this.casecols);
      this.casefieldsstring=this.uniqueFields(mfields).join(',');
    }
  }
}
</script>

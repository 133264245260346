<template>
  <div class="mandati">
    <gnetHeader></gnetHeader>

    <gnetTabBar
      v-bind:titoloapp="'Backoffice'"
      v-bind:tabs="$store.state.tabs"
      v-bind:active_tab="$store.state.active_tab"
      v-on:click="changeTab($event)"
    />

    <div class="slds-grid">
      <div class="slds-col slds-size_2-of-3">

        <gnetRecordHeader
          v-bind:object="'opportunity'"
          v-bind:title="'Apporto '+record.Name"
          
          v-bind:fields="headlineFields"
          v-bind:actions="headlineActions"
          v-on:click="actionClick($event)"
        />

        <article class="slds-card">
          <div class="slds-card__body slds-card__body_inner">

            <gnetFormMask v-on:click="toggleSection($event)" v-bind:sections="sections">

            </gnetFormMask>

          </div>
          <footer class="slds-card__footer">
          </footer>
        </article>

      </div>
      <div class="slds-col slds-size_1-of-3">
        <gnetTimeline v-bind:records="timeline">
        </gnetTimeline>
      </div>


    </div>

    <gnetModal 
      v-if="showModal" 
      v-bind:title="'Esito Callback'"
      v-on:cancel="showModal=false"
      v-on:submit="creaCallback"
      v-bind:hspace="'small'"
    >
      <gnetPicklist
        v-bind:title="'Esito'"
        v-bind:options="esiti"
        v-bind:value="esito"
        v-on:change="esito=$event"
      />
      <gnetFormField v-bind:title="'Note'" v-bind:type="'textarea'"
        v-bind:value="esitoNote" v-bind:placeholder="'Note...'" v-on:change="esitoNote=$event"
      />

    </gnetModal>

    <gnetSpinner v-bind:busy="busy"></gnetSpinner>

  </div>
</template>

<script>
// @ is an alias to /src
// import numeral from 'numeral'
import gnetHeader from '../components/header.vue'
import gnetTabBar from '../components/tabbar.vue'
import gnetRecordHeader from '../components/recordheader.vue'
import gnetFormMask from '../components/formmask.vue'
import gnetTimeline from '../components/timeline.vue'
import gnetModal from '../components/modal.vue'
import gnetPicklist from '../components/picklist.vue'
import gnetFormField from '../components/formfield.vue'
import gnetSpinner from '../components/spinner.vue'
import apiclient from '@/apiclient.js'
import gnetMixLib from '@/mixin.js'

export default {
  name: 'Apporto',
  mixins: [gnetMixLib],
  components: {
    gnetHeader,
    gnetTabBar,
    gnetRecordHeader,
    gnetFormMask,
    gnetTimeline,
    gnetModal,
    gnetPicklist,
    gnetFormField,
    gnetSpinner    
  },
  data: function() {
    return {
      busy: false,
      record: {},
      headlineFields: [],
      headlineActions: [],
      sections:[],
      timeline: [],
      showModal: false,
      apporticols:[],
      apporti:[],
      esiti: [
        { label:'Positivo', value:'Positivo'},
        { label:'Negativo', value:'Negativo'},
      ],
      esito:'',
      esitoNote:'',
    };
  },
  mounted: function() {
    this.setupHeaderFields();
    this.setupRecordFields();
    this.setupQueryFields();
    this.getRecord();

    this.getTimeline();
  },
  methods: {
    toggleSection: function(index) {
      this.sections[index].opened=!this.sections[index].opened;
    },
    creaCallback: function() {
      this.record.Stato_Callback__c = 'Esito '+this.esito;
      this.updateRecordFields(); // aggiorna la maschera

      var body={ Stato_Callback__c: this.record.Stato_Callback__c };
      var self=this;
      self.busy=true;
      apiclient.update(this.$store.getters.connection, 'Apporto__c', this.record.Id, body).then(function() {
        var body={ WhatId: self.record.Id, Subject: 'Esito seconda Callback:'+self.esito, Type: 'Seconda Callback', Description: self.esitoNote, Utente__c: self.$store.state.user.email, Status: 'Completata', ActivityDate: new Date() };
        return apiclient.newTask(self.$store.getters.connection, body);
      }).then(function(resp) {
        console.log(resp);
        self.getTimeline();
        self.showModal=false;
        self.busy=false;
      }).catch(function(err) {
        console.log(err);
        self.showModal=false;
        self.busy=false;
      })
    },
    actionClick: function(actionName) {
      console.log('action!'+actionName);
      if (actionName=='Inserisci seconda callback') {
        this.esito='';
        this.esitoNote='';
        this.showModal=true;
      }
    },
    getRecord: function() {
      var self=this;
      this.recordId=this.$route.params.id;
      var soql='select '+this.fieldsstring+' from Apporto__c where id=\''+this.recordId+'\'';
      console.log(soql);
      apiclient.query(this.$store.getters.connection, soql).then(function(data) {
        console.log('records:'+data.length);
        if (data.length>0)
          self.record=data[0];
        self.updateRecordFields();
      }).catch(function(err){
        console.log(err);
      })
    },
    updateRecordFields: function() {
      // header
      this.updateFieldsValue(this.headlineFields);
      // record
      this.sections.forEach(function(s) {
        this.updateFieldsValue(s.fields);
      }, this);

      this.headlineActions=[];
      if (this.record['Stato_Callback__c']=='Da Effettuare') { // inizio flusso approvazione
        if (this.checkAuth('Effettuare Callback'))
          this.headlineActions.push( { name:'Inserisci seconda callback',  } );
      }

    },
    setupQueryFields: function() {
      var mfields=new Map();

      // id e name sempre
      mfields.set('Id', null);
      mfields.set('Name', null);
      // header
      this.mapFields(mfields, this.headlineFields);
      // record
      this.sections.forEach(function(s) {
        this.mapFields(mfields, s.fields);
      },this);
      // riassume
      this.fields=this.uniqueFields(mfields);
      this.fieldsstring=this.fields.join(',');
    },
    setupData: function() {
      this.record = { Id:'0060O00000zf5CUQAY', Alert:'Parere richiesto', Name:'FWM06649 - Titoli e Liquiditá/Polizze', Account__r_idCodCliente__c: '00026829', StageName:'Mandato accettato',  Owner_Name: 'Fabrizio Rescali' , Assistente__c:'boh', CST__c:'boh', Data_accettazione_mandato__c:'14/02/2020' };
    },
    setupHeaderFields: function() {
      this.headlineFields.push( { value:'', label:'Prot.', name:'Name' } );
      this.headlineFields.push( { value:'', label:'Tipo', name:'Tipo_apporto__c' } );
      this.headlineFields.push( { value:'', label:'Controvalore', name:'Controvalore_apporto__c', type:'currency' } );
      this.headlineFields.push( { value:'', label:'Stato Approvazione', name:'Status_approvazione__c' } );
      this.headlineFields.push( { value:'', label:'Stato Callback', name:'Stato_Callback__c', type:'alert' } );

    },
    setupRecordFields: function() {
      var sectionFields=[];
      sectionFields.push( { id:1, value:'', title:'Nome Apporto', name:'Name', type:'text', placeholder:'scrivi...', tabindex:0, disabled: true} );
      sectionFields.push( { id:2, value:'', title:'Owner', name:'Owner.Name', type:'text', placeholder:'scrivi...', tabindex:0, disabled: true} );
      sectionFields.push( { id:3, value:'', title:'Apporto del', name:'Data_apporto__c', type:'date', placeholder:'scrivi...', tabindex:0, disabled: true} );
      sectionFields.push( { id:4, value:'', title:'Tipo di Apporto', name:'Tipo_apporto__c', type:'text', placeholder:'scrivi...', tabindex:0, disabled: true} );
      sectionFields.push( { id:5, value:'', title:'Controvalore', name:'Controvalore_apporto__c', type:'currency', placeholder:'scrivi...', tabindex:0, disabled: true} );
      this.sections.push( {
        order: 1,
        title: 'Dettagli',
        opened: true,
        fields: sectionFields 
      });

      sectionFields=[];
      sectionFields.push( { id:1, value:'', title:'Opportunità', name:'Opportunita__r.Name', type:'decimal', placeholder:'scrivi...', tabindex:0, disabled: true} );
      sectionFields.push( { id:2, value:'', title:'Controvalore prima dell\'apporto', name:'Controvalore_prima_dell_apporto__c', type:'decimal', placeholder:'scrivi...', tabindex:0, disabled: true} );
      this.sections.push( {
        order: 2,
        title: 'OPPORTUNITA\'',
        opened: true,
        fields: sectionFields 
      });

      sectionFields=[];
      sectionFields.push( { id:1, value:'', title:'KYC Apporto', name:'Icona_Status_KYC_Apporto__c', type:'decimal', placeholder:'scrivi...', tabindex:0, disabled: true} );
      sectionFields.push( { id:2, value:'', title:'KYC Valutazione Apporto', name:'Icona_Status_KYC_Valutazione_Apporto__c', type:'decimal', placeholder:'scrivi...', tabindex:0, disabled: true} );
      sectionFields.push( { id:3, value:'', title:'Nuova KYC Cliente', name:'Icona_Status_Nuova_KYC_Cliente__c', type:'decimal', placeholder:'scrivi...', tabindex:0, disabled: true} );
      this.sections.push( {
        order: 3,
        title: 'KYC',
        opened: true,
        fields: sectionFields 
      });

      sectionFields=[];
      sectionFields.push( { id:1, value:'', title:'Data Approvazione', name:'Data_approvazione__c', type:'decimal', placeholder:'scrivi...', tabindex:0, disabled: true} );
      sectionFields.push( { id:2, value:'', title:'Approvazione', name:'Icona_Approvazione__c', type:'decimal', placeholder:'scrivi...', tabindex:0, disabled: true} );
      this.sections.push( {
        order: 4,
        title: 'APPROVAZIONE',
        opened: true,
        fields: sectionFields 
      });

    },
    getTimeline: function() {
      var self=this;
      var soql='select Id, Subject, ActivityDate, Description, Utente__c, Type from Task where whatId=\''+this.recordId+'\' order by ActivityDate desc';
      console.log(soql);
      apiclient.query(this.$store.getters.connection, soql).then(function(data) {
        console.log('records:'+data.length);
        data.forEach(function(d) { d.show=false; });
        self.timeline=data;
      }).catch(function(err){
        console.log(err);
      })
    },
    setupApporti: function() {

      // Protocollo, fase, funzionario, assistente, CST, data apertura mandato
      var ord=1;
      this.apporticols.push( { order: ord++, label:'Prot.', name:'Name', type:'String' } );
      this.apporticols.push( { order: ord++, label:'Tipo', name:'Tipo__c', type:'String' } );
      this.apporticols.push( { order: ord++, label:'Controvalore', name:'Controvalore__c', type:'Decimal' } );
      this.apporticols.push( { order: ord++, label:'Stato Approvazione', name:'Stato_Approvazione__c', type:'String' } );

      this.apporti.push( { Id:'0060O00000zf5CUQAY', Name:'AP-000000025', Tipo__c: 'Apporto iniziale' , Controvalore__c:'1000000.00',  Stato_Approvazione__c: 'Non Richiesta'  } );

    }
  }
}
</script>

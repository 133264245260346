<template lang="html">

    <div class="slds-page-header">

      <div class="slds-page-header__row">

        <div class="slds-page-header__col-title">
          <div class="slds-media">
            <div class="slds-media__figure">
              <span class="slds-icon_container slds-icon-standard-account" v-bind:title="object">
                <svg class="slds-icon slds-page-header__icon" aria-hidden="true">
                  <use v-bind:xlink:href="'/assets/icons/standard-sprite/svg/symbols.svg#'+object"></use>
                </svg>
                <span class="slds-assistive-text">{{object}}</span>
              </span>
            </div>
            <div class="slds-media__body">
              <div class="slds-page-header__name">
                <div class="slds-page-header__name-title">
                  <h1>
                    <span class="slds-page-header__title slds-truncate" v-bind:title="title">{{title}}</span>
                  </h1>
                </div>
              </div>
              <p v-if="subtitle" class="slds-page-header__name-meta">{{subtitle}}</p>
            </div>
          </div>
        </div>

        <div  class="slds-page-header__col-actions">
          <div class="slds-page-header__controls">

            <div v-for="action in actions" v-bind:key="action.name" class="slds-page-header__control">

              <button v-on:click.prevent="$emit('click',action.name)" 
              v-bind:class="action.class?action.class:'slds-button_neutral'"
              class="slds-button slds-button_stateful slds-not-selected" aria-live="assertive">
                <span class="slds-text-not-selected">
                  <svg v-if="action.symbol" class="slds-button__icon slds-button__icon_small slds-button__icon_left" aria-hidden="true">
                    <use v-bind:xlink:href="'/assets/icons/utility-sprite/svg/symbols.svg#'+action.symbol"></use>
                  </svg>
                  {{action.name}}
                </span>
              </button>

            </div>

          </div>
        </div>

      </div>

      <div v-if="fields" class="slds-page-header__row slds-page-header__row_gutters">
        <div class="slds-page-header__col-details">
          <ul class="slds-page-header__detail-row">
            <li v-for="(field) in fields" v-bind:key="field.label" class="slds-page-header__detail-block">
              <div class="slds-text-title slds-truncate" v-bind:title="field.label">{{field.label}}</div>
              <div v-bind:title="field.value">
                <a v-if="field.link" v-bind:href="field.link">{{field.value | filter(field.type) }}</a>
                <span v-if="field.type=='alert' && field.value" class="slds-icon_container slds-icon-utility-announcement" v-bind:title="'Alert'">
                  <svg class="slds-icon slds-icon-text-default slds-icon_x-small" aria-hidden="true">
                    <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#alert"></use>
                  </svg>
                  <span class="slds-assistive-text">Alert</span>
                </span>
                <span v-if="!field.link">{{field.value | filter(field.type) }}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>

    </div>
</template>

<script>
import numeral from 'numeral'
import moment from 'moment'

export default {
  name: 'gnetRecordHeader',
  components: {
  },
  props: {
    object: String,
    title: String,
    subtitle: String,

    fields: Array,
    actions: Array,
  },
  data() {
    return {
    }
  },
  filters: {
    filter: function(v, type) {
      switch(type) {
        case 'date':
          var n=new moment(v);
          return n.format('DD/MM/YYYY');
        case 'currency':
          return numeral(v).format('$ 0,0.00');
        case 'alert':
            return v;
      }
      return v;
    }
  },
  computed: {

  },
  async created() {

  },
  methods: {
  }
}
</script>

<style lang="" scoped>

</style>

export default {
    methods: {
        checkAuth: function(authname) {
//            console.log('verifica autorizzazione:'+authname)
            if (!this.$store.getters.connection.userdata) return false;
            if (this.$store.getters.connection.userdata.includes(authname)) return true;
            return false;
        },
        changeTab: function(tabName) {
            this.$store.commit('SET_ACTIVE_TAB', tabName.name);
            this.$router.push( { name: tabName.name } );
          },
      
        getFieldValue: function(obj, path) {
        // eslint-disable-next-line no-redeclare
        for (var i=0, path=path.split('.'), len=path.length; i<len; i++){
            if (obj)
            obj = obj[path[i]];
        }
        return obj;
        },
        updateFieldsValue: function(fields) {
            fields.forEach(function(f) {
                f.value=this.getFieldValue(this.record,f.name);
            }, this);
        },
        mapFields: function(mfields, fields) {
            // header
            fields.forEach(function(f) {
                if (f.type=='picklist')
                    mfields.set('toLabel('+f.name+')', f);
                else
                    mfields.set(f.name, f);
            });
        },
        uniqueFields: function(mfields) {
            var fields=[];
            var it=mfields.keys();
            var f=it.next();
            while (!f.done){
                fields.push(f.value);
                f=it.next();
            }
            return fields;
        },
    }
}
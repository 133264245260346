<template>
    <div>
        <gnetHeader></gnetHeader>

        <gnetTabBar
        v-bind:titoloapp="'Backoffice'"
        v-bind:tabs="$store.state.tabs"
        v-bind:active_tab="$store.state.active_tab"
        v-on:click="changeTab($event)"
        />
        <div style="margin: 10px">
          <gnetRecordHeader
          v-bind:object="'account'"
          v-bind:title="'Clienti'"
          v-bind:subtitle="'Account'"
          v-bind:actions="headlineActions"
          v-on:click="actionClick($event)"
        />
        <gnetTable
      v-bind:cols="cols"
      v-bind:records="records"
      v-on:click="click($event)"
      v-on:filter="filter($event)"
    >      
    </gnetTable>

    <gnetModal
      v-if="filterPanel.show"
      v-bind:title="'Applica filtro'"
      v-bind:object="'opportunity'"
      v-on:cancel="filterPanel.show=false"
      v-on:submit="doFilter"
    >
        <label class="slds-form-element__label" v-bind:for="'form-element-1'">{{filterPanel.name}}</label>

        <div class="slds-form-element__control">
          <input v-model="filterPanel.value" type="text" id="form-element-2" placeholder="filtro..." class="slds-input" />
        </div>
    </gnetModal>
    <gnetModal
      v-if="newAccount"
      v-bind:title="'Nuovo Account: Clienti'"
      v-bind:object="'account'"
      v-on:cancel="newAccount=false"
      v-on:submit="insertAccount"
    >
        <div class="slds-clearfix slds-card allow-horizontal-form wide-input-break full-width">
          <div class="slds-grid slds-gutters_small full cols-2">
              <div class="slds-col slds-grid slds-has-flexi-truncate  full ">
                <div class="slds-form-element slds-form-element_readonly slds-grow slds-hint-parent override--slds-form-element">
                  <div class="test-id__field-label-container slds-form-element__label">
                  <span class="test-id__field-label" style="padding-right:35px;">Ragione Sociale</span>
                  </div>
                  <div class="slds-form-element__control slds-grid itemBody">
                  <input v-model="acc.Name" 
                   class="test-id__field-value slds-form-element__static slds-grow  is-read-only slds_input"
                    v-bind:class="!acc.Name?'is-invalid':'is-valid'"
                    maxlength="100"
                    type="text" />
                    </div>
                </div>
              </div>
              <div class="slds-col slds-grid slds-has-flexi-truncate  full ">
                <div class="slds-form-element slds-form-element_readonly slds-grow slds-hint-parent override--slds-form-element">
                  <div class="test-id__field-label-container slds-form-element__label">
                  <span class="test-id__field-label" style="padding-right:35px;">Partita Iva</span>
                  </div>
                  <div class="slds-form-element__control slds-grid itemBody">
                  <input
                    class="test-id__field-value slds-form-element__static slds-grow  is-read-only slds_input"
                    v-model="acc.partIva__c" 
                    maxlength="100"
                    type="text"
                  />
                  </div>
                </div>
              </div>
            </div>
            <div class="slds-grid slds-gutters_small full cols-2 ">
              <div class="slds-col slds-grid slds-has-flexi-truncate  full forcePageBlockItem forcePageBlockItemView">
                <div class="slds-form-element slds-form-element_readonly slds-grow slds-hint-parent override--slds-form-element">
                  <div class="test-id__field-label-container slds-form-element__label">
                  <span class="test-id__field-label" style="padding-right:35px;">Codice Fiscale</span>
                  </div>
                  <div class="slds-form-element__control slds-grid itemBody">
                  <input v-model="acc.codiceFisc__c" 
                   class="test-id__field-value slds-form-element__static slds-grow  is-read-only slds_input"
                    v-bind:class="!acc.codiceFisc__c?'is-invalid':'is-valid'"
                    maxlength="100"
                    type="text" />
                    </div>
                </div>
              </div>
              <div class="slds-col slds-grid slds-has-flexi-truncate  full forcePageBlockItem forcePageBlockItemView">
                <div class="slds-form-element slds-form-element_readonly slds-grow slds-hint-parent override--slds-form-element">
                  <div class="test-id__field-label-container slds-form-element__label">
                  <span class="test-id__field-label" style="padding-right:35px;">Telefono</span>
                  </div>
                  <div class="slds-form-element__control slds-grid itemBody">
                  <input
                    class="test-id__field-value slds-form-element__static slds-grow  is-read-only slds_input"
                    v-model="acc.Phone" 
                    maxlength="100"
                    type="text"
                  />
                  </div>
                </div>
              </div>
            </div>
        </div>
    </gnetModal>
    </div>
    </div>
</template>

<script>
// @ is an alias to /src
// import numeral from 'numeral'
import gnetHeader from '../components/header.vue'
import gnetTabBar from '../components/tabbar.vue'
import gnetTable from '../components/table.vue'
import gnetModal from '../components/modal.vue'
import apiclient from '@/apiclient.js'
import gnetMixLib from '@/mixin.js'
import gnetRecordHeader from '../components/recordheader.vue'

export default {
  name: 'Accounts',
  mixins: [gnetMixLib],
  components: {
    gnetHeader,
    gnetTabBar,
    gnetTable,
    gnetModal,
    gnetRecordHeader
  },
  data: function() {
    return {
        cols: [],
        records: [],
        headlineFields: [],
        headlineActions: [/*{ name:'Nuovo', class:'slds-button_neutral' }*/],
        fieldsstring: '',
        filterPanel: {
          show: false,
          value:'',
          name:'',
          index:0,
          filterCol: {}
        },
        newAccount: false,
        acc: {}
    };
  },
  mounted: function() {
    this.setupRecordFields();
    this.getRecords();
  },
  methods: {
    actionClick: function(actionName){
      if(actionName == 'Nuovo') this.newAccount = true;
    },
    doFilter: function() {
      var col=this.cols[this.filterPanel.index];
      col.filtered=this.filterPanel.value;
      this.filterPanel.show=false;
      this.getRecords();
    },
    filter: function(index) {
      console.log('filter');
      var col=this.cols[index];
      if (col.filtered) {
        col.filtered=false;
        col.filterValue='';
        this.getRecords();
      } else {
        this.filterPanel.index=index;
        this.filterPanel.name=col.label;
        this.filterPanel.filterCol=col;
        this.filterPanel.value='';
        this.filterPanel.show=true;
      }
    },
    getRecords: function() {
      var self=this;
      var filtri=[];
      this.cols.forEach(function(d){
        if (d.filtered)
          filtri.push(d.name+'=\''+d.filtered+'\'');
      });
      var soql='select '+this.fieldsstring+' from Account';
      soql+=' '+this.$store.state.connection.userdata.Where_Account__c;
      
      var swhere=' and '+filtri.join(' and ');
      if (filtri.length>0)
        soql=soql+swhere;
      soql+=' order by lastmodifieddate desc limit 100';
      console.log(soql);
      apiclient.query(this.$store.getters.connection, soql).then(function(data) {
        console.log('records:'+data.length);
        self.records = [];
        data.forEach(function(d){
          d.Qualita = 'Buono';
          self.records.push(d);
        });
      }).catch(function(err){
        console.log(err);
      })
    },
    click: function(record) {
      console.log(record);
      this.$router.push({ name:'Account', params: { id: record.Id } });
    },
    setupRecordFields: function() {
      // Protocollo, fase, funzionario, assistente, CST, data apertura mandato
      var ord=1;
      var con = this.$store.getters.connection;
      //this.cols.push( { order: ord++, value:'', label:'Nome e Cognome', name:'Name', type:'string' } );
      //this.cols.push( { order: ord++, value:'', label:'Partita Iva', name:'partIva__c', type:'string' } );
      //this.cols.push( { order: ord++, value:'', label:'Codice Fiscale', name:'codiceFisc__c', type:'string' } );
      this.cols.push( { order: ord++, value:'', label:'Codice Cliente', name:'idCodCliente__c', type:'string' } );
      this.cols.push( { order: ord++, value:'', label:'Cellulare', name:'PersonMobilePhone', type:'string' } );
      if(con.userdata.Name != 'Ufficio Archivio')this.cols.push( { order: ord++, value:'', label:'Email', name:'PersonEmail', type:'string' } );
      this.cols.push( { order: ord++, value:'', label:'Qualità del dato', name:'Qualita', type:'string' } );

      var mfields=new Map();
      mfields.set('Id', null);
      this.mapFields(mfields, this.cols);
      this.fieldsstring=this.uniqueFields(mfields).join(',');
      this.fieldsstring = this.fieldsstring.replace(',Qualita', '');

    },
    insertAccount: function(){
      var self = this;
      apiclient.insert(this.$store.getters.connection, 'Account', this.acc)
        .then(function(resp) {
            console.log(resp);
            self.newAccount = false;
            alert('Fornitore inserito!');
        })
        .catch(function(err) {
            console.log(err);
            alert('Errore nell\'inserimento: '+err);
        })
    }
  }
}
</script>
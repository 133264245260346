<template>
  <div class="home">

    <gnetHeader v-if="showheader"></gnetHeader>

    <gnetTabBar
      v-bind:titoloapp="'Backoffice'"
      v-bind:tabs="$store.state.tabs"
      v-bind:active_tab="$store.state.active_tab"
      v-on:click="changeTab($event)"
    >
    </gnetTabBar>

  </div>
</template>

<script>
// @ is an alias to /src
// import numeral from 'numeral'
// import apiclient from '@/apiclient.js'
import gnetHeader from '../components/header.vue'
import gnetTabBar from '../components/tabbar.vue'
import gnetMixLib from '@/mixin.js'

export default {
  name: 'Home',
  mixins: [gnetMixLib],
  components: {
    gnetHeader,
    gnetTabBar
  },
  data: function() {
    return {
        showheader: true,
        dragging: false,
        brick: {
          x: 10,
          y: 100,
          w: 200,
          h: 200,
          title: 'first brick'
        },
        xtmp:10,
        ytmp:100,
    };
  },
  created: function() {
    console.log('registering service worker');
    if ('serviceWorker' in navigator) {
      console.log('service worker available');
      navigator.serviceWorker.register('/sw.js');
    }
  },
  computed: {
  },
  mounted: function() {
  },
  methods: {
    dragstart: function(e) {
      this.xtmp=e.pageX;
      this.ytmp=e.pageY;
      this.dragging=true;
      console.log(e);
    },
    dragmove: function(e) {
      this.brick.x=e.pageX;
      this.brick.y=e.pageY;
      //console.log(e);
    },
    dragend: function(e) {
      this.dragging=false;
      this.brick.x=e.pageX;
      this.brick.y=e.pageY;
    },
    brickStyle: function(thebrick) {
      var s="position: fixed;";
      s+="top:"+thebrick.y+"px;";
      s+="left:"+thebrick.x+"px;";
      s+="width:"+thebrick.w+"px;";
      s+="height:"+thebrick.h+"px;";
      if (this.dragging)
        s+="border: 3px solid grey;";
      else
        s+="border: 1px solid black;";

      // console.log(s);
      return s;
    }
  }
}
</script>
<style scoped>
</style>


<template lang="html">
    <div class="pippo" align="center" style="margin: 15px">
        <div v-if="prosegui == false && caricamento == false">
            <form method="post" action="" enctype="multipart/form-data"> 
                <div class="slds-form-element">
                    <span class="slds-form-element__label" id="file-selector-primary-label">Attachment</span>
                    <div class="slds-form-element__control">
                        <div class="slds-file-selector slds-file-selector_files">
                            <div class="slds-file-selector__dropzone"> 
                                <input v-on:change="fileUpload" multiple="true" class="slds-file-selector__input slds-assistive-text" type="file" id="file" ref="file" name="File" aria-labelledby="file-selector-primary-label file-selector-secondary-label" />
                                <label class="slds-file-selector__body" for="file" id="file-selector-secondary-label">
                                    <span class="slds-file-selector__button slds-button slds-button_neutral">Upload Files</span>
                                </label>
                            </div> 
                        </div>
                    </div>
                </div>
            </form>  
            <div v-for="file in files" align="center" v-bind:key="file.name" style="margin: 20px">
                <div class="slds-scope">
                    <table>
                        <tr>
                            <td style="text-align:center;">  {{file.name}} - {{file.size}}</td>
                            <td> <button class="slds-button slds-button_text-destructive" type="button" v-on:click="remove(files.indexOf(file+''))" title="Remove file">Rimuovi</button></td>
                        </tr>
                    </table>
                </div>
            </div>
            <button class="slds-button slds-button_neutral" style="margin: 10px; color: #027f5c !important;" type="submit" v-if="files.length > 0" v-on:click="prosegui = true;getSog()">Prosegui</button>
        </div>
        <div v-if="prosegui && caricamento == false" align="center" style="margin: 10px">
            <table class="slds-table slds-table_cell-buffer slds-table_bordered slds-table_striped slds-table_fixed-layout">
                <thead>
                    <tr>
                        <th class="slds-truncate">File</th>
                        <th>Entità</th>
                        <th>Categoria</th>
                        <th>Tipo Documento</th>
                        <th v-if="visSog">Soggetto</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(file, index) in files" v-bind:key="file.order">
                        <td class="slds-truncate">{{file.name}}</td>
                        <td>{{file.entity}}</td>
                        <td>
                            <select class="slds-select" v-model="file.cat" v-on:change="onChangeC($event, index)">
                                <option v-for="cat in file.categories" v-bind:value="cat.value" v-bind:key="cat.order">
                                    {{ cat.text }}
                                </option>
                            </select>
                        </td>
                        <td>
                            <select class="slds-select" v-model="file.tipoDoc" v-on:change="onChangeTD($event, index)">
                                <option v-for="td in file.ftipiD" v-bind:value="td.value" v-bind:key="td.order">
                                    {{ td.text }}
                                </option>
                            </select>
                        </td>
                        <td v-if="file.sogs != undefined && file.sogs.length > 0 ">
                            <select class="slds-select" v-model="file.sogg">
                                <option v-for="sog in file.sogs" v-bind:value="sog.value" v-bind:key="sog.order">
                                    {{ sog.text }}
                                </option>
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>                    
            <button class="slds-button slds-button_brand" style="margin: 10px; background-color: #027f5c !important;" type="submit" v-on:click="saveDocs(); prosegui = false; caricamento = true;">Salva
            </button>
        </div>
        <div v-if="caricamento" role="status" class="slds-spinner slds-spinner_brand slds-spinner_x-small slds-input__spinner">
            <span class="slds-assistive-text">Loading</span>
            <div class="slds-spinner__dot-a"></div>
            <div class="slds-spinner__dot-b"></div>
        </div>
    </div>
</template>

<script>

import apiclient from '@/apiclient.js'

export default {
  name: 'uploadDocs',
  components: {},
  props: {
    rId: String,
    rec: Object,
    ent: String,
    con: Object,
    utentePort: String,
    related: String,
  },
  data() {
    return {
        files: [],
        fileli: [],
        fileData: [],
        tipiDoc:[],
        lsog:[],
        prosegui: false,
        categories: [],
        visSog: false,
        caricamento: false,
    }
  },
  mounted: function() {
      this.getTipoDoc();
  },
  methods: {
    getTipoDoc: function(){
        //and (Tipo_Prodotto__c in'+ this.record.prodotto__c+' or Tipo_Prodotto__c = null) 
        var contr = this.rec.TbTipMand_tipoMandato_id__c !== undefined? this.rec.TbTipMand_tipoMandato_id__c:'null';
        var ogg = this.rec.TbOggRapp_oggRapporto_id__c !== undefined? this.rec.TbOggRapp_oggRapporto_id__c:'null';
        var soql='select id, Name, Tipo_Contratto__c, Tipo_Prodotto__c, Oggetto__c, Pubblico_su_Soggetto__c, Oggetto_Pertinenza__c, toLabel(Oggetto_Pertinenza__c) ogP, Famiglia_Documento__c from Tipo_Documento__c where Oggetto_Pertinenza__c = \'Opportunity\' and (Tipo_Contratto__c = \''+contr+'\' or Tipo_Contratto__c = null) and (Oggetto__c =\''+ogg+'\' or Oggetto__c = null) and Pubblicabile__c = true and Pubblico_su_Soggetto__c = true order by name';
        if(this.ent == 'Task' && !this.rec.Tipo_Documento__c) soql = 'select id, Name, Tipo_Contratto__c, Tipo_Prodotto__c, Oggetto__c, Pubblico_su_Soggetto__c, Oggetto_Pertinenza__c, toLabel(Oggetto_Pertinenza__c) ogP, Famiglia_Documento__c from Tipo_Documento__c where Name =\'Contabili\'';
        if(this.ent == 'Task' && this.rec.Tipo_Documento__c) soql = 'select id, Name, Tipo_Contratto__c, Tipo_Prodotto__c, Oggetto__c, Pubblico_su_Soggetto__c, Oggetto_Pertinenza__c, toLabel(Oggetto_Pertinenza__c) ogP, Famiglia_Documento__c from Tipo_Documento__c where Id =\''+this.rec.Tipo_Documento__c+'\'';
        if(this.ent == 'Account') soql = 'select id, Name, Tipo_Contratto__c, Tipo_Prodotto__c, Oggetto__c, Pubblico_su_Soggetto__c, Oggetto_Pertinenza__c, toLabel(Oggetto_Pertinenza__c) ogP, Famiglia_Documento__c from Tipo_Documento__c where Oggetto_Pertinenza__c =\'Account\' and Pubblicabile__c = true';
        console.log(soql);
        var self= this;
        apiclient.query(this.$store.getters.connection, soql).then(function(data) {
        console.log('records:'+data.length);
        self.tipiDoc=data;
        }).catch(function(err){
        console.log(err);
        })
    },
    getSog: function(){
        console.log('opp:')
        console.log(this.rId);
        console.log(this.rec);
        console.log(this.sogTask);
        var soqlacc = '';
        if(this.related == 'Opportunity') soqlacc = 'select Id from Account '+this.con.userdata.Where_Account__c;
        var soql='';
        if(this.rec.Soggetto_Legame_Doc__c) soql='SELECT id, Persona_fisica_giuridica__c,Persona_fisica_giuridica__r.idCodCliente__c, Persona_fisica_giuridica__r.Name, toLabel(tipoLegame_id__c), System_E_person_account__c FROM AnRappLeg__c where Id = \''+this.rec.Soggetto_Legame_Doc__c+'\'';
        else soql='SELECT id, Persona_fisica_giuridica__c,Persona_fisica_giuridica__r.idCodCliente__c, Persona_fisica_giuridica__r.Name, toLabel(tipoLegame_id__c), System_E_person_account__c FROM AnRappLeg__c where tipoLegame_id__c in (\'A\',\'M\',\'I\') and idCodRapp__c = \''+this.rId+'\' order by name';
        console.log(soql);
        var self= this;
        if(soqlacc !== ''){
            apiclient.query(this.$store.getters.connection, soqlacc).then(function(data) {
                console.log('records:'+data.length);
                var accs = [];
                data.forEach(function(d){
                    accs.push(d.Id);
                });
                apiclient.query(self.$store.getters.connection, soql).then(function(data2) {
                    console.log('records:'+data2.length);
                    self.lsog=[];
                    data2.forEach(function(d){
                        if(accs.includes(d.Persona_fisica_giuridica__c)) self.lsog.push(d);
                    });
                }).catch(function(err){
                console.log(err);
                })
            }).catch(function(err){
            console.log(err);
            })
        }else{
            apiclient.query(this.$store.getters.connection, soql).then(function(data) {
            console.log('records:'+data.length);
            self.lsog=data;
            }).catch(function(err){
            console.log(err);
            })
        }
    },
    setdatiFile: function(){
        this.tipiDoc.forEach(function(d, i){
            if (this.categories.some(({ value }) => value == d.Famiglia_Documento__c)){
                                            return;
                                           }
            this.categories.push({text: d.Famiglia_Documento__c, value: d.Famiglia_Documento__c, order: i});
        },this);
        this.files.forEach(function(d,i){
            d.order = i;
            d.categories = [];
            d.categories = this.categories;
        }, this);
    },
    onChangeC: function(event, index){
        console.log('on Change Cat');
        console.log(event.target.value);
        console.log(index);
        this.visSog = false;
        this.files[index].sogg=[];
        this.files[index].sogs = [];

        var value = event.target.value;
        this.files[index].ftipiD.splice(0,this.files[index].ftipiD.length);
        var ind = 0;
        this.tipiDoc.forEach(function(d){
            if(d.Famiglia_Documento__c == value && d.Oggetto_Pertinenza__c == this.files[index].entity){
                this.files[index].ftipiD.push({text: d.Name, value:d.Id, order: ind});
                ind++;
            }
        },this);
        console.log(this.files[index]);
    },
    onChangeTD: function (event, index)
    {
        console.log('onchangetd');
        var value = event.target.value;
        var sogPresenti = false;
        this.visSog = false;
        this.files[index].sogg = [];
        this.files[index].sogs = [];
        this.tipiDoc.forEach(function(d){
            if(d.Id == value && d.Pubblico_su_Soggetto__c)sogPresenti = true;
        });
        if(sogPresenti){
            this.visSog = true;
            var tsogs=[];
            this.lsog.forEach(function(d, i){
                var t = d.Persona_fisica_giuridica__r.idCodCliente__c+' '+d.tipoLegame_id__c;
                tsogs.push({text: t, value: d.Id, order: i});
            }, this);
            this.files[index].sogs = tsogs;
        }else{
            this.visSog = false;
            this.files.forEach(function(d){
                if(d.sogs != undefined && d.sogs.length > 0 ) this.visSog = true;
            },this);
                    
        }
        
    },
    saveDocs: function() {
        console.log(this.fileData);
        var self = this;
        var idOpp = this.rId;
        if(this.ent == 'Task') idOpp = this.rec.WhatId;
        this.fileData.forEach(function(d, i){
            var doc= self.related==='Account'?{
                Name: self.files[i].name,
                Tipo_Documento_Lookup__c: self.files[i].tipoDoc,
                Anagrafica__c: idOpp,
                RelateId__c: idOpp,
                Utente_Portale_Created__c: self.utentePort,
                caricato_da_BackOffice__c: true,
                Versione__c:0,
                Nome_Attachment__c: self.files[i].name
            }:{
                Name: self.files[i].name,
                Tipo_Documento_Lookup__c: self.files[i].tipoDoc,
                Utente_Portale_Created__c: self.utentePort,
                Opportunita__c: idOpp,
                RelateId__c: idOpp,
                caricato_da_BackOffice__c: true,
                Versione__c:0,
                Nome_Attachment__c: self.files[i].name
            };
            console.log('document');
            console.log(doc);
            var att={
                Name: self.files[i].name,
                Body: d
            };
            var dl={};
            var dlnec = false;
            if(self.files[i].sogg.length>0){
                dl={
                    Soggetto_Legame__c: self.files[i].sogg,
                    Nr_Ordine_Documento__c: 1,
                    Stato_Documento__c: 'Pubblicato',
                };
                dlnec=true;
            }
            if(self.related == 'Account'){
                dl={
                    Account__c: idOpp,
                    Nr_Ordine_Documento__c: 1,
                    Stato_Documento__c: 'Pubblicato',
                };
                dlnec=true;
            }
            console.log('attachment:')
            console.log(att)
            apiclient.insert(self.$store.getters.connection, 'Documento__c', doc)
            .then(function(resp) {
                console.log(resp);
                att.ParentId = resp.data.id;
                doc.Id = resp.data.id;
                dl.Documento__c = resp.data.id;
                apiclient.insert(self.$store.getters.connection, 'Attachment', att)
                .then(function(resp) {
                    console.log(resp);
                    if(dlnec){
                        apiclient.insert(self.$store.getters.connection, 'Documento_Legame__c', dl)
                        .then(function(resp) {
                            console.log(resp);
                            self.caricamento = false;
                            self.files = [];
                            self.prosegui = false;
                            alert('Documenti Caricati');
                            self.$emit('click');
                        })
                        .catch(function(err) {
                            self.caricamento = false;
                            self.files = [];
                            self.prosegui = false;
                            alert('Il caricamento è andato in errore! Contattare l\'amministratore');
                            console.log(err);
                        })
                    }else{
                        self.caricamento = false;
                        self.files = [];
                        self.prosegui = false;
                        alert('Documenti Caricati');
                        if(self.ent === 'Task')self.$emit('click', doc.Id);
                        else self.$emit('click');
                    }
                })
                .catch(function(err) {
                    self.caricamento = false;
                    self.files = [];
                    self.prosegui = false;
                    alert('Il caricamento è andato in errore! Contattare l\'amministratore');
                    console.log(err);
                })
            })
            .catch(function(err) {
                alert('Il caricamento è andato in errore! Contattare l\'amministratore');
                self.caricamento = false;
                self.files = [];
                self.prosegui = false;
                console.log(err);
            })
            /*
            var body = {
                        Name: this.files[i].name,
                        ContentType: d.type,
                        Body: d.data,
                        ParentId: this.rId
                    };
            apiclient.insert(this.$store.getters.connection, 'Attachment', body).then(function(resp) {
                console.log(resp);
            });*/

        });
    },
    fileUpload: function(e) {
        var fileList=e.target.files;
            this.lfiles = fileList;
        if (this.$refs.file.files.length>0 && this.prosegui == false) {
            this.fileli=this.$refs.file.files;
            console.log(this.myfile);
            var self = this;
            this.fileli.forEach(function (d) {
                if(self.ent === 'Task')self.files.push({name: d.name, entity: self.related, size: d.size, ftipiD: []});
                else self.files.push({name: d.name, entity: self.ent, size: d.size, ftipiD: []});
                const reader = new FileReader();
                reader.onload = self.doReadFile
                reader.readAsDataURL(d);
            });

            this.setdatiFile();
        }
    },
    doReadFile: function (e) {
        console.log('doReadFile');
        console.log(e);
        this.fileData.push(e.target.result.split(',')[1]);
    },
    remove(i) {
        this.files.splice(i, 1);
    },
  }
}
</script>

<style scoped>

</style>
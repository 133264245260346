<template lang="html">
    <header >
      <a href="javascript:void(0);" class="slds-assistive-text slds-assistive-text_focus">Skip to Navigation</a>
      <a href="javascript:void(0);" class="slds-assistive-text slds-assistive-text_focus">Skip to Main Content</a>
      <div class="slds-global-header slds-grid slds-grid_align-spread">

        <div class="slds-global-header__item">
          <div class="mylogo"></div>
        </div>

        <div class="slds-global-header__item slds-global-header__item_search">
            <gnetLookup 
              v-bind:label="'Cerca'"
              v-bind:object="'Account'"
              v-bind:founded="founded"
              v-bind:selected="found"
              v-on:change="search"
              v-on:click="selected($event)"
            ></gnetLookup>
        </div>

        <div class="slds-global-header__item">
          <ul class="slds-global-actions">
            <gnetHeaderNotification v-bind:notifications="notifications" v-on:readed="readed($event)"></gnetHeaderNotification>
            <li class="slds-global-actions__item">
              <div class="slds-dropdown-trigger slds-dropdown-trigger_click">
                <button v-on:click="avatar" class="slds-button slds-global-actions__avatar slds-global-actions__item-action" title="person name" aria-haspopup="true">
                  <span class="slds-avatar slds-avatar_circle slds-avatar_medium">
                    <img v-if="!$store.state.user.imageUrl" alt="Person name" src="/assets/images/avatar2.jpg" title="User avatar" />
                    <img v-if="$store.state.user.imageUrl" alt="Person name" v-bind:src="$store.state.user.imageUrl" title="User avatar" />
                  </span>
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </header>
</template>

<script>
import gnetLookup from './lookup.vue'
import gnetHeaderNotification from './headernotification.vue'

export default {
  name: 'gnetHeader',
  components: {
    gnetHeaderNotification,
    gnetLookup
  },
  props: {
    zone: Array,
    logo: String,

  },
  data() {
    return {
      founded: [],
      found:undefined,
      notifications:[
        /*
        {
          order: 1,
          title: 'messaggio 1',
          content:'sono il messaggio 1',
          datetime:'',
          who: 'Paolo',
          avatar: 'avatar1.jpg',
          unreaded:true,
        },
        {
          order: 2,
          title: 'messaggio 2',
          content:'sono il messaggio 2',
          datetime:'',
          who: 'Mario',
          avatar: '',
          unreaded:true,
        }
        */
      ],
    }
  },
  mounted: function() {
  },
  async created() {

  },
  methods: {
    search: function() {
        this.founded=[
          /*
          {
            id:1,
            name:'uno',
            value:'ciao'
          },
          {
            id:2,
            name:'due',
            value:'bau'
          }
          */
        ];
    },
    selected: function(index) {
        console.log('selected:'+index);
        console.log(this.founded[index]);
    },
    avatar: function() {
      this.$store.dispatch('signOut');
    },
    readed: function(index) {
      console.log('readed');
      this.notifications[index].unreaded=false;
    }
  }
}
</script>

<style scoped>
.mylogo {
    width: 12.5rem;
    height: 2.5rem;
    background-image: url(/images/logo/UFLogo.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
}
</style>

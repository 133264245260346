/* global gapi */

<template lang="html">
  <div class="demo-only" style="height:24rem; display:none" v-show="opendialog">
    <section role="alertdialog" tabindex="0" aria-labelledby="prompt-heading-id" aria-describedby="prompt-message-wrapper" class="slds-modal slds-fade-in-open slds-modal_prompt" aria-modal="true">
      <div class="slds-modal__container">
        <header class="slds-modal__header slds-theme_error slds-theme_alert-texture">
          <button class="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse" title="Close">
            <svg class="slds-button__icon slds-button__icon_large" aria-hidden="true">
              <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
            </svg>
            <span class="slds-assistive-text">Close</span>
          </button>
          <h2 class="slds-text-heading_medium" id="prompt-heading-id">Google Login</h2>
        </header>
        <div class="slds-modal__content slds-p-around_medium" id="prompt-message-wrapper">
          <p>Per procedere è necessario identificarsi premendo il pulsante</p>
            <div id="google-login" style="float:right"></div>
        </div>
        <footer class="slds-modal__footer slds-theme_default">

        </footer>
      </div>
    </section>
    <div class="slds-backdrop slds-backdrop_open"></div>
  </div>
</template>

<script>
export default {
  name: 'login',
  data: function() {
    return {
      opendialog: false,
      titolo: 'ciao',
    }
  },
  mounted: function() {
    var self=this;
    
    setTimeout(function() {
      self.opendialog=true;
    },2000);
    // eslint-disable-next-line no-undef
    gapi.signin2.render('google-login', {
      onsuccess: this.onSignIn
    });
  },
  methods: {
    close: function() {

    },
    onSignIn: function(googleUser) {
      this.$store.commit('SET_GOOGLE_IDENTITY', googleUser);
      this.$emit('login', googleUser);
    },
  }
}
</script>

<style scoped>

</style>

<template lang="html">

<div v-if="busy">
  <div class="slds-spinner_container slds-is-fixed">
    <div role="status" class="slds-spinner slds-spinner_medium">
      <span class="slds-assistive-text">Loading</span>
      <div class="slds-spinner__dot-a"></div>
      <div class="slds-spinner__dot-b"></div>
    </div>
  </div>
</div>

</template>

<script>

export default {
  name: 'gnetSpinner',
  props: {
    busy:Boolean
  },
  data() {
    return {
    }
  },
}
</script>

<style scoped>
</style>

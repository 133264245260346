<template>
  <div class="mandati">
    <gnetHeader></gnetHeader>

    <gnetTabBar
      v-bind:titoloapp="'Backoffice'"
      v-bind:tabs="$store.state.tabs"
      v-bind:active_tab="$store.state.active_tab"
      v-on:click="changeTab($event)"
    />
    <div class="slds-grid">
      <div class="slds-col slds-tabs_card slds-size_2-of-3" style="margin: 5px;">
        <div class="slds-card slds-card_boundary headerBottomBorder forceRelatedListCardDesktop">
        <div class="highlights slds-clearfix slds-page-header slds-page-header_record-home fixed-position">
        <gnetRecordHeader
          v-bind:object="'task'"
          v-bind:title="record.Subject"
          v-bind:fields="headlineFields"
          v-bind:actions="headlineActions"
          v-on:click="actionClick($event)"
        />

        <article class="slds-card">
          <div class="slds-card__body slds-card__body_inner">

            <gnetFormMask v-on:click="toggleSection($event)" v-bind:sections="sections">

            </gnetFormMask>

          </div>
          <footer class="slds-card__footer">
          </footer>
        </article>
        </div>
        </div>
        
      </div>
      <div v-if="record.Adeguata_Verifica__c || record.Richiesta_Documento__c" class="slds-col">
        <div class="slds-tabs_card" style="margin: 5px;">
          <div class="slds-card slds-card_boundary forceBaseCard flexipageCard" style="margin-top: 15px">
          <uploadDocs v-bind:rId="record.Id" v-bind:utentePort="$store.state.connection.userdata.Id" v-bind:rec="record" v-bind:ent="'Task'" v-bind:related="who" v-on:click="upTask($event)">
          </uploadDocs>
          </div>
        </div>
      </div>
      <gnetModal 
      v-if="showEsito" 
      v-bind:title="'Inserisci Parere'"
      v-on:cancel="showEsito=false"
      v-on:submit="upEsito"
      v-bind:hspace="'small'"
    >

      <gnetPicklist
        v-bind:title="'Sintesi Parere'"
        v-bind:options="esiti"
        v-bind:value="esito"
        v-on:change="esito=$event"
      />

    </gnetModal>
    </div>

    <gnetSpinner v-bind:busy="busy"></gnetSpinner>

  </div>
</template>

<script>
// @ is an alias to /src
// import numeral from 'numeral'
import gnetHeader from '../components/header.vue'
import gnetTabBar from '../components/tabbar.vue'
import gnetRecordHeader from '../components/recordheader.vue'
import gnetFormMask from '../components/formmask.vue'
import gnetSpinner from '../components/spinner.vue'
import uploadDocs from '../components/uploadDocs.vue'
import apiclient from '@/apiclient.js'
import gnetMixLib from '@/mixin.js'

export default {
  name: 'Task',
  mixins: [gnetMixLib],
  components: {
    gnetHeader,
    gnetTabBar,
    gnetRecordHeader,
    gnetFormMask,
    gnetSpinner,
    uploadDocs
  },
  data: function() {
    return {
      busy: false,
      recordId:'',
      record: {},

      editAccount: false,
      fields:[],
      fieldsstring: '',
      headlineFields: [],
      headlineActions: [],
      sections:[],

      apporticols:[],
      apportifieldsstring:'',
      apporti:[],
      who:'',

      timeline: [],

      // pareri
      showCommento: false,
      showEsito: false,
      showParere: false,
      esiti: [
        { label:'SI', value:'SI'},
        { label:'NO', value:'NO'},
      ],
      esito:'',
      esitoNote:'',

      // tariffari
      showTariffario: false,
      tariffari: [],
      tariffario:'',
      tariffarioNote:'',

    };
  },
  mounted: function() {
    this.setupHeaderFields();
    this.setupRecordFields();
    this.setupQueryFields();
    this.getRecord();
  },
  methods: {
    removeAlert: function(fields, alert) {
      if (!fields) return fields;
      var a=fields.split(';');
      var b=[];
      a.forEach(function(v){
        if (alert!=v)
          b.push(v);
      });
      return b.join(';');
    },
    toggleSection: function(index) {
      this.sections[index].opened=!this.sections[index].opened;
    },
    getRecord: function() {
      var self=this;
      this.recordId=this.$route.params.id;
      var soql='select '+this.fieldsstring+' from Task where id=\''+this.recordId+'\'';
      console.log(soql);
      apiclient.query(this.$store.getters.connection, soql).then(function(data) {
        console.log('records:'+data.length);
        console.log(data)
        if (data.length>0){
          self.record=data[0];
          self.who = self.record.WhatId.startsWith('001')? 'Account' : 'Opportunity';
          if(self.who == 'Account'){
            var soql2='select idCodCliente__c from Account where id=\''+self.record.WhatId+'\'';
            console.log(soql2);
            apiclient.query(self.$store.getters.connection, soql2).then(function(d2) {
              console.log(d2)
              self.record.What= {idCodCliente__c: d2[0].idCodCliente__c};
              console.log(self.record);
              self.updateRecordFields();
            });
          }
        }
          
      }).catch(function(err){
        console.log(err);
      })
    },
    updateRecordFields: function() {
      // header
      this.updateFieldsValue(this.headlineFields);
      // record
      this.sections.forEach(function(s) {
        this.updateFieldsValue(s.fields);
      }, this);
      this.headlineActions=[{ name:'Chiudi', class:'slds-button_neutral' }, { name:'Record Correlato', class:'slds-button_neutral' }];
      if(this.record.Adeguata_Verifica__c) this.headlineActions.push({ name:'Esito Verifica', class:'slds-button_neutral' });
    },
    setupQueryFields: function() {
      var mfields=new Map();

      // id sempre
      mfields.set('Id', null);
      mfields.set('WhatId', null);
      mfields.set('Adeguata_Verifica__c', null);
      mfields.set('Verifica_codice_positiva__c', null);
      mfields.set('Documento__c', null);
      mfields.set('Tipo_Documento__c', null);
      mfields.set('Richiesta_Documento__c', null);
      mfields.set('Soggetto_Legame_Doc__c', null);
      
      // header
      this.mapFields(mfields, this.headlineFields);
      // record
      this.sections.forEach(function(s) {
        this.mapFields(mfields, s.fields);
      },this);
      // riassume
      this.fields=this.uniqueFields(mfields);
      var filtered = this.fields.filter(function(value){ 
        return value != 'What.idCodCliente__c';
    });
      this.fieldsstring=filtered.join(',');
    },
    setupHeaderFields: function() {
        
      this.headlineFields.push( { value:'', label:'Oggetto', name:'Subject', type:'string' } );
      this.headlineFields.push( { value:'', label:'Tipo', name:'Type', type:'string' } );
      this.headlineFields.push( { value:'', label:'Stato', name:'Status', type:'string' } );
      //this.cols.push( { order: ord++, value:'', label:'Descrizione', name:'Description', type:'string' } );
      this.headlineFields.push( { value:'', label:'Data Scadenza', name:'ActivityDate', type:'string' } );
      this.headlineFields.push( { value:'', label:'Correllato a', name:'What.idCodCliente__c', type:'string' } );
      this.headlineFields.push( { value:'', label:'Su', name:'Who.Name', type:'string' } );

    },
    actionClick: function(actionName){
      if(actionName == 'Modifica') this.editAccount = true;
      if(actionName == 'Chiudi'){
        var self = this;
        var tUp= {};
        tUp.Status = 'Completata';
        apiclient.update(this.$store.getters.connection, 'Task', this.recordId, tUp)
          .then(function(resp) {
              console.log(resp);
              self.getRecord();
              alert('Task Chiuso!');
          })
          .catch(function(err) {
              console.log(err);
              alert('Errore nell\'aggiornamento: '+err);
          })
      }
      if(actionName == 'Record Correlato'){
        if(this.record.WhatId.startsWith('001'))this.$router.push({ name:'Account', params: { id: this.record.WhatId } });
        if(this.record.WhatId.startsWith('006'))this.$router.push({ name:'Mandato', params: { id: this.record.WhatId } });
      }
      if(actionName == 'Esito Verifica'){
        this.showEsito = true;
      }
    },
    setupRecordFields: function() {
      var sectionFields=[];
      sectionFields.push( { id:1, value:'', title:'Oggetto', name:'Subject', type:'text', placeholder:'scrivi...', tabindex:0, disabled: true} );
      sectionFields.push( { id:6, value:'', title:'Richiesto da', name:'Owner.Name', type:'text', placeholder:'scrivi...', tabindex:0, disabled: true} );
      sectionFields.push( { id:2, value:'', title:'Tipo', name:'Type', type:'text', placeholder:'scrivi...', tabindex:0, disabled: true} );
      sectionFields.push( { id:3, value:'', title:'Data Scadenza', name:'ActivityDate', type:'text', placeholder:'scrivi...', tabindex:0, disabled: true} );
      sectionFields.push( { id:4, value:'', title:'Correllato a', name:'What.idCodCliente__c', type:'text', placeholder:'scrivi...', tabindex:0, disabled: true} );
      sectionFields.push( { id:5, value:'', title:'Su', name:'Who.Name', type:'text' , placeholder:'scrivi...', tabindex:0, disabled: true} );
      sectionFields.push( { id:5, value:'', title:'Su', name:'Who.Name', type:'text' , placeholder:'scrivi...', tabindex:0, disabled: true} );
      sectionFields.push( { id:6, value:'', title:'Descrizione', name:'Description', type:'text', placeholder:'scrivi...', tabindex:0, disabled: true} );
      this.sections.push( {
        order: 1,
        title: 'Dettagli',
        opened: true,
        fields: sectionFields 
      });
    },
    upTask: function(evt){
      var tUp= {};
      var self = this;
        tUp.Documento__c = evt;
        if(this.record.Verifica_codice_positiva__c == 'SI') tUp.Status = 'Completata';
        apiclient.update(this.$store.getters.connection, 'Task', this.recordId, tUp)
          .then(function(resp) {
              console.log(resp);
              self.getRecord();
              if(tUp.Status == 'Completata')alert('Task Chiuso!');
              else{
                alert('Task aggiornato correttamente!');
              }
          })
          .catch(function(err) {
              console.log(err);
              alert('Errore nell\'aggiornamento: '+err);
          })
    },
    upEsito: function(){
      var tUp= {};
        tUp.Verifica_codice_positiva__c = this.esito;
        if(this.record.Documento__c) tUp.Status = 'Completata';
        apiclient.update(this.$store.getters.connection, 'Task', this.recordId, tUp)
          .then(function(resp) {
              console.log(resp);
              self.getRecord();
              if(tUp.Status == 'Completata')alert('Task Chiuso!');
              else{
                alert('Task aggiornato correttamente!');
              }
          })
          .catch(function(err) {
              console.log(err);
              alert('Errore nell\'aggiornamento: '+err);
          })
    }
  }
}
</script>

<template>
  <div class="mandati">
    <gnetHeader></gnetHeader>

    <gnetTabBar
      v-bind:titoloapp="'Backoffice'"
      v-bind:tabs="$store.state.tabs"
      v-bind:active_tab="$store.state.active_tab"
      v-on:click="changeTab($event)"
    />
    <div style="overflow: auto; margin: 10px">
    <gnetTable
      v-bind:cols="cols"
      v-bind:records="records"
      v-on:click="click($event)"
      v-on:filter="filter($event)"
    >      
    </gnetTable>

    <gnetModal
      v-if="filterPanel.show"
      v-bind:title="'Applica filtro'"
      v-bind:object="'opportunity'"
      v-on:cancel="filterPanel.show=false"
      v-on:submit="doFilter"
    >
        <label class="slds-form-element__label" v-bind:for="'form-element-1'">{{filterPanel.name}}</label>

        <div class="slds-form-element__control">
          <input v-model="filterPanel.value" type="text" id="form-element-2" placeholder="filtro..." class="slds-input" />
        </div>
    </gnetModal>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import numeral from 'numeral'
import gnetHeader from '../components/header.vue'
import gnetTabBar from '../components/tabbar.vue'
import gnetTable from '../components/table.vue'
import gnetModal from '../components/modal.vue'
import apiclient from '@/apiclient.js'
import gnetMixLib from '@/mixin.js'

export default {
  name: 'Mandati',
  mixins: [gnetMixLib],
  components: {
    gnetHeader,
    gnetTabBar,
    gnetTable,
    gnetModal
  },
  data: function() {
    return {
        cols: [],
        records: [],
        fieldsstring: '',
        filterPanel: {
          show: false,
          value:'',
          name:'',
          index:0,
          filterCol: {}
        }
    };
  },
  mounted: function() {
    this.setupRecordFields();
    this.getRecords();
  },
  methods: {
    doFilter: function() {
      var col=this.cols[this.filterPanel.index];
      col.filtered=this.filterPanel.value;
      this.filterPanel.show=false;
      this.getRecords();
    },
    filter: function(index) {
      console.log('filter');
      var col=this.cols[index];
      if (col.filtered) {
        col.filtered=false;
        col.filterValue='';
        this.getRecords();
      } else {
        this.filterPanel.index=index;
        this.filterPanel.name=col.label;
        this.filterPanel.filterCol=col;
        this.filterPanel.value='';
        this.filterPanel.show=true;
      }
    },
    getRecords: function() {
      var self=this;
      var filtri=[];
      this.cols.forEach(function(d){
        if (d.filtered)
          filtri.push(d.name+' like \'%'+d.filtered+'%\'');
      });
      var con = this.$store.getters.connection;
      if(con.userdata.Name == 'Ufficio Archivio'){
        var accsql='select id from Account '+con.userdata.Where_Account__c;
        var oppsql='select '+this.fieldsstring+' from Opportunity where (IsWon = true)';
      console.log(accsql);
        apiclient.query(this.$store.getters.connection, accsql).then(function(data) {
          console.log('records:'+data.length);
          var accIds='';
          data.forEach(function(d){
            accIds+='\''+d.Id+'\',';
          })
          accIds = accIds.slice(0, -1)
          oppsql+=' and AccountId in ('+accIds+') ';
          var swhere=' and '+filtri.join(' and ');
          if (filtri.length>0)
            oppsql=oppsql+swhere;
          oppsql+=' order by lastmodifieddate desc ';
          apiclient.query(self.$store.getters.connection, oppsql).then(function(data) {
          console.log('records:'+data.length);
          self.records=data;
          }).catch(function(err){
            console.log(err);
          })
        }).catch(function(err){
          console.log(err);
        })
      }else{
        var soql='select '+this.fieldsstring+' from Opportunity';
        var t = con.userdata.Where_Opportunita__c;
        t = t.replaceAll('\\','');
        soql+=' '+t;
        var swhere=' and '+filtri.join(' and ');
        if (filtri.length>0)
          soql=soql+swhere;
        soql+=' order by lastmodifieddate desc ';
        console.log(soql);
        apiclient.query(this.$store.getters.connection, soql).then(function(data) {
          console.log('records:'+data.length);
          self.records=data;
        }).catch(function(err){
          console.log(err);
        })
      }
    },
    click: function(record) {
      console.log(record);
      this.$router.push({ name:'Mandato', params: { id: record.Id } });
    },
    setupRecordFields: function() {
      // Protocollo, fase, funzionario, assistente, CST, data apertura mandato
      var ord=1;
//      this.cols.push( { order: ord++, value:'', label:'Prot.', name:'Name', type:'string' } );
      this.cols.push( { order: ord++, value:'', label:'Codice mandato', name:'AnRapp_id__c', type:'string' } );
      this.cols.push( { order: ord++, value:'', label:'Oggetto', name:'TbOggRapp_oggRapporto_id__c', type:'picklist' } );
      
      this.cols.push( { order: ord++, value:'', label:'Alert', name:'Backoffice_Alert__c', type:'alert' } );
      this.cols.push( { order: ord++, value:'', label:'Codice Cliente', name:'Account.idCodCliente__c', type:'string' } );
      this.cols.push( { order: ord++, value:'', label:'Fase', name:'StageName', type:'string' } );
      this.cols.push( { order: ord++, value:'', label:'Funzionario', name:'Owner.Name', type:'string' } );
      this.cols.push( { order: ord++, value:'', label:'Assistente', name:'Assistente__r.Name', type:'string' } );
      this.cols.push( { order: ord++, value:'', label:'CST', name:'Owner.CST__c', type:'string' } );
      this.cols.push( { order: ord++, value:'', label:'Data accettazione mandato', name:'dtAccettaz__c', type:'date' } );
      this.cols.push( { order: ord++, value:'', label:'Data sottoscrizione', name:'dtSottoscr__c', type:'date' } );

      var mfields=new Map();
      mfields.set('Id', null);
      this.mapFields(mfields, this.cols);
      this.fieldsstring=this.uniqueFields(mfields).join(',');

    }
  }
}
</script>

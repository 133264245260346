/* eslint-disable no-console */

import axios from 'axios'
import jwt from 'jsonwebtoken'

/*
const apiClient = axios.create({
  //  baseURL: `http://localhost:3000`,
  withCredentials: false, // This is the default
})
*/

export default {
  secret: '',
  setSecret(s) {
//    console.log('setSecret');
//    console.log(s);
    this.secret=s;
  },
  getKey(filename) {
    console.log('getkey:'+filename);

    this.secret='e2ffccce2a69190b8f3f16896b2cadeae380caaaae7e7fac2f31a83bd55757c5';

    return axios.get(filename);
  },
  get(con, metaname) {
    var self=this;
    return new Promise(function(resolve,reject) {
      console.log('get meta');
      var url = "./getmeta";
      var payload = {
        token: con.mytoken,
        payload: metaname
      }
      console.log(payload);
      console.log(self.secret);
      var jwtpayload = jwt.sign( payload, self.secret );
      axios.post(url, { payload: jwtpayload } ).then(function(resp) {
        console.log('ricevuto');
        var data = resp.data;
        resolve(data);
      }).catch(function(err) {
        console.log('problemi');
        reject(err);
      });
        
    });
  },

}

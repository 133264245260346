<template lang="html">

  <div class="test-listViewManager slds-card slds-card_boundary slds-grid slds-grid--vertical forceListViewManager">
    <table class="slds-table slds-table_cell-buffer slds-table_bordered slds-table_col-bordered slds-table_striped slds-table_fixed-layout">

      <thead>
        <tr class="slds-line-height_reset">
          <th v-for="(col, index) in cols" v-bind:key="col.order">
            <span class="pippo" v-on:click="$emit('filter',index)">{{col.label}}</span>

            <svg v-if="col.filtered" class="slds-icon slds-icon-text-default slds-icon_x-small" aria-hidden="true">
              <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#filterList"></use>
            </svg>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(record, rindex) in records" v-bind:key="record.id" class="slds-hint-parent">

          <td v-for="(col,index) in cols" v-bind:key="col.order" v-bind:style="'text-align:'+align(col.type)" class="slds-truncate">
            <a v-if="index==0 && (nolink == false|| nolink == undefined) && linkspecial == false" v-bind:href="$route.path+'/'+record['Id']" v-on:click.prevent="select(rindex)">{{record[col.name]}}</a>
            <a v-if="index==0 && (nolink == false|| nolink == undefined) && linkspecial == true" v-on:click="getAttachments(index)">{{record[col.name]}}</a>
            <span  v-if="index==0 && nolink == true">
              {{getFieldValue(record,col.name) | filter(col.type) }}
            </span>
            <span v-if="index>0 && col.type!='Button'" >

              <span v-if="col.type=='alert' && record[col.name]" class="slds-icon_container slds-icon-utility-announcement" v-bind:title="'Alert'">
                <svg class="slds-icon slds-icon-text-default slds-icon_x-small" aria-hidden="true">
                  <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#alert"></use>
                </svg>
                <span class="slds-assistive-text">Alert</span>
              </span>
              
              
              {{getFieldValue(record,col.name) | filter(col.type) }}
              
            </span>
            <span v-if="index>0 && col.type=='Button'" >
              <button v-if="record[col.name] == 'Spubblica'" v-on:click="$emit('azione', ['spub',record])" class="slds-button slds-button_neutral">{{record[col.name]}}</button>
              <button v-if="record[col.name] == 'Sollecita'" v-on:click="$emit('azione', ['soll',record])" class="slds-button slds-button_neutral">{{record[col.name]}}</button>
              <!--button v-if="record[col.name] == 'Sollecita'" v-on:click="$emit('azione', ['elim',record])" class="slds-button slds-button_neutral">{{record[col.name]}}</button-->
              <button v-if="record[col.name] == 'Sollecita'" v-on:click="$emit('azione', ['elim',record])" class="slds-button slds-button_icon slds-button_icon-border slds-button_icon-medium" title="More Options">
                <svg class="slds-button__icon" aria-hidden="true">
                  <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#delete"></use>
                </svg>
                <span class="slds-assistive-text">More options</span>
              </button>
            </span>

          </td>

        </tr>
      </tbody>
    </table>
  </div>

</template>

<script>
/*
  cols
    label
    order
    name
    type
  records
    name
*/

import numeral from 'numeral'
import moment from 'moment'
import apiclient from '@/apiclient.js'
import { saveAs } from 'file-saver';

export default {
  name: 'gnetTable',
  components: {},
  props: {
    cols: Array,
    records: Array,
    linkspecial: Boolean,
    nolink: Boolean
  },
  data() {
    return {
    }
  },
  watch: {
  },
  computed: {},
  mounted: function() {
      this.recordId=this.$route.params.id;
  },
  filters: {
    filter: function(v, type) {
      switch(type) {
        case 'date':
          var n=new moment(v);
          return n.format('DD/MM/YYYY');
        case 'currency':
          return numeral(v).format('$ 0,0.00');
        case 'alert':
            return v;
      }
      return v;
    }
  },
  methods: {
    getFieldValue: function(obj, path) {
      // eslint-disable-next-line no-redeclare
      for (var i=0, path=path.split('.'), len=path.length; i<len; i++){
        if (obj)
          obj = obj[path[i]];
      }
      return obj;
    },
    align: function(type) {
      switch(type) {
        case 'Currency':
        case 'Decimal':
          return 'right';
      }
      return 'left';
    },
    select: function(index) {
      console.log('record select');
        this.$emit('click', this.records[index]);
    },

    getAttachments: function(index) {
      console.log('GET ATTACHMENT');
      var soql = 'select Name from Attachment where ParentId = \''+this.records[index].Id+'\'';
      var self = this;
      apiclient.query(this.$store.getters.connection, soql).then(function(data) {
          self.$store.dispatch('getattachment', {docId: self.records[index].Id}).then(function(resp) {
            console.log(resp.toString());
              var blob = self.b64toBlob(resp.data, 'application/pdf');
              //var file = new File(blob, data[0].Name);
              saveAs(blob, data[0].Name);
            });
      });
    },
    b64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || '';
      sliceSize = sliceSize || 512;
      var byteCharacters = atob(b64Data);
      var byteArrays = [];
      for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);
        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      var blob = new Blob(byteArrays, {type: contentType});
      return blob;
    }



            

  }
}
</script>

<style scoped>

</style>

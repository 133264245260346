<template>
    <div>
        <gnetHeader></gnetHeader>

        <gnetTabBar
        v-bind:titoloapp="'Backoffice'"
        v-bind:tabs="$store.state.tabs"
        v-bind:active_tab="$store.state.active_tab"
        v-on:click="changeTab($event)"
        />
        <div style="margin: 10px">
          <gnetRecordHeaderView
          v-bind:object="'task'"
          v-bind:views="views"
          v-bind:subtitle="'Task'"
          v-bind:actions="headlineActions"
          v-on:click="actionClick($event)"
        />
        <gnetTable
      v-bind:cols="cols"
      v-bind:records="records"
      v-on:click="click($event)"
      v-on:filter="filter($event)"
    >      
    </gnetTable>

    <gnetModal
      v-if="filterPanel.show"
      v-bind:title="'Applica filtro'"
      v-bind:object="'opportunity'"
      v-on:cancel="filterPanel.show=false"
      v-on:submit="doFilter"
    >
        <label class="slds-form-element__label" v-bind:for="'form-element-1'">{{filterPanel.name}}</label>

        <div class="slds-form-element__control">
          <input v-model="filterPanel.value" type="text" id="form-element-2" placeholder="filtro..." class="slds-input" />
        </div>
    </gnetModal>
    </div>
    </div>
</template>

<script>
// @ is an alias to /src
// import numeral from 'numeral'
import gnetHeader from '../components/header.vue'
import gnetTabBar from '../components/tabbar.vue'
import gnetTable from '../components/table.vue'
import gnetModal from '../components/modal.vue'
import apiclient from '@/apiclient.js'
import gnetMixLib from '@/mixin.js'
import gnetRecordHeaderView from '../components/recordheaderview.vue'

export default {
  name: 'Tasks',
  mixins: [gnetMixLib],
  components: {
    gnetHeader,
    gnetTabBar,
    gnetTable,
    gnetModal,
    gnetRecordHeaderView
  },
  data: function() {
    return {
        cols: [],
        records: [],
        headlineFields: [],
        headlineActions: [/*{ name:'Nuovo', class:'slds-button_neutral' }*/],
        fieldsstring: '',
        filterPanel: {
          show: false,
          value:'',
          name:'',
          index:0,
          filterCol: {}
        },
        views: [
            {order: 0, name: 'Tutti i Task Aperti', selected: false, where: '(Utente__c != null or Richiesto_Backoffice__c = true) and Status = \'Aperta\''},
            {order: 1, name: 'Tutti i Task Chiusi', selected: false, where: 'Richiesto_Backoffice__c = true and Status = \'Completata\''},
            {order: 2, name: 'Tutti i Task Non Eseguiti', selected: false, where: 'Richiesto_Backoffice__c = true and Status = \'Non Eseguita\''},
            {order: 3, name: 'Miei Task', selected: false, where: 'Utente__r.Email__c = \''+this.$store.state.connection.myid+'\' and Richiesto_Backoffice__c = true'}
        ],
        view: {},
        newTask: false,
        ta: {}
    };
  },
  mounted: function() {
      this.view = this.views[0];
    this.setupRecordFields();
    this.getRecords();
  },
  methods: {
    actionClick: function(actionName){
      if(actionName == 'Nuovo') this.newTask = true;
      if(actionName instanceof Object){
        this.view = actionName;
        this.getRecords();
      }
    },
    doFilter: function() {
      var col=this.cols[this.filterPanel.index];
      col.filtered=this.filterPanel.value;
      this.filterPanel.show=false;
      this.getRecords();
    },
    filter: function(index) {
      console.log('filter');
      var col=this.cols[index];
      if (col.filtered) {
        col.filtered=false;
        col.filterValue='';
        this.getRecords();
      } else {
        this.filterPanel.index=index;
        this.filterPanel.name=col.label;
        this.filterPanel.filterCol=col;
        this.filterPanel.value='';
        this.filterPanel.show=true;
      }
    },
    getRecords: function() {
      var self=this;
      var filtri=[];
      this.cols.forEach(function(d){
        if (d.filtered)
          filtri.push(d.name+'=\''+d.filtered+'\'');
      });
      var soql='select '+this.fieldsstring+' from Task';
      console.log(this.$store.state.connection);

      var swhere=' where '+filtri.join(' and ');
      if (filtri.length>0)
        soql=soql+swhere+' and '+this.view.where;
      else
          soql+= ' where '+this.view.where;
      soql+=' '+this.$store.state.connection.userdata.Where_Task__c;
      soql+=' order by lastmodifieddate desc limit 100';
      console.log(soql);
      apiclient.query(this.$store.getters.connection, soql).then(function(data) {
        console.log('records:'+data.length);
        self.records=[];
        data.forEach(function(d){
          if(d.WhatId.startsWith('001')){
            var soql2='select idCodCliente__c from Account where id=\''+d.WhatId+'\'';
            console.log(soql2);
            apiclient.query(self.$store.getters.connection, soql2).then(function(d2) {
              console.log(d2)
              d.What.Name= d2[0].idCodCliente__c;
              console.log(d);
              self.records.push(d);
            });
          }else{
            console.log(d);
            self.records.push(d);
          }
        })
      }).catch(function(err){
        console.log(err);
      })
    },
    click: function(record) {
      console.log(record);
      this.$router.push({ name:'Task', params: { id: record.Id } });
    },
    setupRecordFields: function() {
      var ord=0;
      this.cols.push( { order: ord++, value:'', label:'Oggetto', name:'Subject', type:'string' } );
      this.cols.push( { order: ord++, value:'', label:'Tipo', name:'Type', type:'string' } );
      //this.cols.push( { order: ord++, value:'', label:'Descrizione', name:'Description', type:'string' } );
      this.cols.push( { order: ord++, value:'', label:'Data Scadenza', name:'ActivityDate', type:'string' } );
      this.cols.push( { order: ord++, value:'', label:'Correllato a', name:'What.Name', type:'string' } );
      this.cols.push( { order: ord++, value:'', label:'Su', name:'Who.Name', type:'string' } );

      var mfields=new Map();
      mfields.set('Id', null);
      mfields.set('WhatId', null);
      mfields.set('WhoId', null);
      this.mapFields(mfields, this.cols);
      this.fieldsstring=this.uniqueFields(mfields).join(',');

    },
    insertTask: function(){
      var self = this;
      apiclient.insert(this.$store.getters.connection, 'Task', this.ta)
        .then(function(resp) {
            console.log(resp);
            self.newAccount = false;
            alert('Task inserito!');
        })
        .catch(function(err) {
            console.log(err);
            alert('Errore nell\'inserimento: '+err);
        })
    }
  }
}
</script>
<template lang="html">
  <div class="slds-form-element">
    <label class="slds-form-element__label" for="combobox-unique-id-25">{{title}}</label>
    <div class="slds-form-element__control">
      <div class="slds-combobox_container">
        <div v-on:click="show=!show" v-bind:class="{'slds-is-open': show, 'slds-combobox-picklist': !show}" class="slds-combobox slds-dropdown-trigger slds-dropdown-trigger_click " aria-expanded="false" aria-haspopup="listbox" role="combobox">
          <div class="slds-combobox__form-element slds-input-has-icon slds-input-has-icon_right" role="none">
            <input type="text" class="slds-input slds-combobox__input" id="combobox-unique-id-25" aria-controls="listbox-unique-id" autoComplete="off" role="textbox" v-bind:value="value" v-bind:placeholder="placeholder" readonly="" />
            <span class="slds-icon_container slds-icon-utility-down slds-input__icon slds-input__icon_right" title="Description of icon when needed">
              <svg class="slds-icon slds-icon slds-icon_x-small slds-icon-text-default" aria-hidden="true">
                <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#down"></use>
              </svg>
              <span class="slds-assistive-text">{{title}}</span>
            </span>
          </div>
          <div id="listbox-unique-id" role="listbox" class="slds-dropdown slds-dropdown_fluid">
            <ul class="slds-listbox slds-listbox_vertical" role="presentation">
              <li v-on:click="$emit('change', option.value); show='false'" v-for="(option) in options" v-bind:key="option.value" role="presentation" class="slds-listbox__item">
                <div id="listbox-option-unique-id-01" class="slds-media slds-listbox__option slds-listbox__option_plain slds-media_small slds-media_center" role="option">
                  <span class="slds-media__figure">
                    <svg class="slds-icon slds-icon_x-small slds-listbox__icon-selected" aria-hidden="true">
                      <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"></use>
                    </svg>
                  </span>
                  <span class="slds-media__body">
                    <span class="slds-truncate" v-bind:title="option">{{option.label}}</span>
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'gnetPicklist',
  components: {

  },
  props: {
    id: String,
    name: String,
    value: [String, Number, Date],
    options: Array,
    title: String,
    type: String,
    tabindex: Number,
    disabled: Boolean,
    placeholder: String
  },
  data() {
    return {
      show: false
    }
  },
  computed: {

  },
  async created() {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>

</style>

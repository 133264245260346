<template lang="html">
<div>

  <gnetRecordHeader v-bind:object="'task'" v-bind:title="'Attività'" v-bind:actions="headlineActions" v-on:click="actionClick($event)"/>

  <div class="slds-box slds-theme_shade">

    <ul class="slds-timeline">

      <li v-for="(record,index) in records" v-bind:key="record.Id">

        <div v-bind:class="{'slds-is-open': record.show}" class="slds-timeline__item_expandable slds-timeline__item_task ">
          <span class="slds-assistive-text">{{record.IsTask?'Task':'Event'}}</span>
          <div class="slds-media">

            <div class="slds-media__figure">
              <button v-on:click.prevent="openNote(index)" class="slds-button slds-button_icon" title="Toggle details" aria-controls="task-item-base">
                <svg class="slds-button__icon slds-timeline__details-action-icon" aria-hidden="true">
                  <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#switch"></use>
                </svg>
                <span class="slds-assistive-text">Toggle details</span>
              </button>

              <div class="slds-icon_container slds-icon-standard-task slds-timeline__icon" title="task">
                <svg class="slds-icon slds-icon_small" aria-hidden="true">
                  <use v-bind:xlink:href="'/assets/icons/standard-sprite/svg/symbols.svg#'+(record.IsTask?'task':'event')"></use>
                </svg>
              </div>
            </div>

            <div class="slds-media__body">
              <div class="slds-grid slds-grid_align-spread slds-timeline__trigger">
                <div class="slds-grid slds-grid_vertical-align-center slds-truncate_container_75 slds-no-space">

                  <h3 class="slds-truncate" v-bind:title="record.Subject">
                    <a href="javascript:void(0);" v-on:click="onTask(record)">
                      <strong>{{record.Subject}}</strong>
                    </a>
                  </h3>

                </div>

                <div class="slds-timeline__actions slds-timeline__actions_inline">
                  <p class="slds-timeline__date">{{record.ActivityDate | datetime}}</p>
                  <button class="slds-button slds-button_icon slds-button_icon-border-filled slds-button_icon-x-small" aria-haspopup="true" title="More Options for this item">
                    <svg class="slds-button__icon" aria-hidden="true">
                      <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#down"></use>
                    </svg>
                    <span class="slds-assistive-text">More Options for this item</span>
                  </button>
                </div>
              </div>

              <article class="slds-box slds-timeline__item_details slds-theme_shade slds-m-top_x-small slds-m-horizontal_xx-small slds-p-around_medium" id="task-item-expanded" aria-hidden="false">
                <div>
                  <span class="slds-text-title">Type</span>
                  <p class="slds-p-top_x-small"><span v-html="record.Type"></span></p>
                </div>
                <div>
                  <span class="slds-text-title">User</span>
                  <p class="slds-p-top_x-small"><span v-html="record.Utente__c"></span></p>
                </div>
                <div>
                  <span class="slds-text-title">Description</span>
                  <p class="slds-p-top_x-small"><span v-html="record.Description"></span></p>
                </div>
              </article>

            </div>
          </div>
        </div>
      </li>

    </ul>

  </div>
  <gnetModal
      v-if="newTask"
      v-bind:title="'Nuova Attività su: '+currentRecord.Name"
      v-bind:object="'task'"
      v-on:cancel="newTask=false"
      v-on:submit="insertTask"
    >
      <div class="slds-form-element slds-form-element_readonly slds-grow slds-hint-parent override--slds-form-element">
        <div class="test-id__field-label-container slds-form-element__label">
        <span class="test-id__field-label" style="padding-right:35px;">Oggetto</span>
        </div>
        <div class="slds-form-element__control slds-grid itemBody">
        <input v-model="task.Subject" 
          class="test-id__field-value slds-form-element__static slds-grow  is-read-only slds_input"
          maxlength="100"
          type="text" />
          </div>
      </div>
      <div class="slds-form-element slds-form-element_readonly slds-grow slds-hint-parent override--slds-form-element">
        <div class="test-id__field-label-container slds-form-element__label">
        <span class="test-id__field-label" style="padding-right:35px;">Descrizione</span>
        </div>
        <div class="slds-form-element__control slds-grid itemBody">
        <input v-model="task.Description" 
          class="test-id__field-value slds-form-element__static slds-grow  is-read-only slds_input"
          maxlength="100"
          type="text" />
          </div>
      </div>

      <div class="slds-form-element slds-form-element_readonly slds-grow slds-hint-parent override--slds-form-element">
        <div class="test-id__field-label-container slds-form-element__label">
        <span class="test-id__field-label" style="padding-right:35px;">Data Scadenza</span>
        </div>
        <div class="slds-form-element__control slds-grid itemBody">
        <input v-model="task.ActivityDate" 
          class="test-id__field-value slds-form-element__static slds-grow  is-read-only slds_input"
          type="date" />
          </div>
      </div>
      <div class="slds-form-element slds-form-element_readonly slds-grow slds-hint-parent override--slds-form-element">
        <div class="test-id__field-label-container slds-form-element__label">
        <span class="test-id__field-label" style="padding-right:35px;">Tipo</span>
        </div>
        <div class="slds-form-element__control slds-grid itemBody">
          <select v-model="task.Type">
            <option value="Call">Chiamata</option>
            <option value="Reclamo">Reclamo</option>
            <option value="Other">Altro</option>
          </select>
          </div>
      </div>
  </gnetModal>
</div>

</template>

<script>
import gnetRecordHeader from './recordheader.vue'
import gnetModal from '../components/modal.vue'
import apiclient from '@/apiclient.js'

export default {
  name: 'gnetTimeline',
  components: {
    gnetModal,
    gnetRecordHeader
  },
  props: {
    records: Array,
    currentRecord: Object,
  },
  data() {
    return {
      headlineActions:[/*{ name:'Nuovo', class:'slds-button_neutral' }*/],
      newTask: false,
      task: {}
    }
  },
  mounted: function() {
  },
  filters: {
    datetime: function(dt) {
      if (dt) {
        var d = dt.substr(0, 10);
        var t = dt.substr(11, 8);
        return d + ' ' + t;
      }
      return dt;
    }
  },
  methods: {
    openNote: function(index) {
      console.log('openNote:'+index);
      this.records[index].show=!this.records[index].show;
    },
    actionClick: function(actionName){
      if(actionName == 'Nuovo') this.newTask = true;
    },
    insertTask: function(){
      var self = this;
      this.task.WhatId = this.currentRecord.Id;
      var con = this.$store.getters.connection;
      var soql = 'select id from Utente_Portale__c where Email__c =\''+con.myid+'\'';
      apiclient.query(this.$store.getters.connection, soql).then(function(data) {
        console.log('records:'+data.length);
        self.task.Utente__c = data[0].Id;
        console.log(self.task);
        apiclient.insert(self.$store.getters.connection, 'Task', self.task)
        .then(function(resp) {
            console.log(resp);
            self.$emit('finish');
            self.newTask = false;
            alert('Attività inserito!');
        })
        .catch(function(err) {
            console.log(err);
            alert('Errore nell\'inserimento: '+err);
        })
      }).catch(function(err){
        console.log(err);
      })
      
    },
    onTask: function(r){
      this.$router.push({ name:'Task', params: { id: r.Id } });
    }
  }
}
</script>

<style lang="" scoped>

</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
  created: function() {
    //this.$store.commit('SET_TABS', [{name:'Home', order:1}, {name:'Mandati', order:2}, {name:'Accounts', order:3}, {name:'Tasks', order:4} ] );
    this.$store.commit('SET_ACTIVE_TAB', 'Home');
  }
}).$mount('#app')

import Vue from 'vue'
import Vuex from 'vuex'
import metaremote from '@/metaremote.js'
import apiclient from '@/apiclient.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    active_tab:'',
    tabs:[],
    mandati:[],

    login: false,
    auth: {},
    connection: {
      myid: '',
      myid_token: '',
      mytokenizedid: '',
      mytoken: '',
      userdata: {},
      zona: '',
    },
    // google payload
    googleIdentity: {},
    // google decoded user
    user: {
      id: '',
      id_token: '',
      name: '',
      imageUrl: '',
      email: ''
    },
  },
  getters: {
    connection: function(state) { return state.connection },
    logged: function(state) { return state.login },
    auth: function(state) { return state.auth },
    googleIdentity: function(state) { return state.googleIdentity },
    userdata: function(state) { return state.connection.userdata },
  },
  mutations: {
    SET_TABS: function(state, payload) { state.tabs=payload },
    SET_ACTIVE_TAB: function(state, payload) { state.active_tab=payload },
    SET_MANDATI: function(state, payload) { state.mandati=payload },
    SET_AUTH: function(state, payload)              { state.auth = payload; },

    SET_CONNECTION: function(state, payload)        {
      state.connection = payload;
      if (payload.userdata && payload.userdata.agentcodes && payload.userdata.agentcodes.length>0) {
        state.connection.zona = payload.userdata.agentcodes[0];
        if (payload.userdata.agentcodes.length>1) state.multizona=true;
      }
    },
    SET_CONNECTION_TOKEN: function(state, payload)  {
      state.connection.mytoken = payload.mytoken;
      state.connection.mytokenizedid = payload.mytokenizedid;
      state.connection.userdata = payload.userdata;
      var t = payload.userdata.Tabs__c;
      t = t.split(';');
      var lt = [{name:'Home', order:1}];
      var ord =1;
      t.forEach(function(d){
        ord++;
        lt.push({name:d, order:ord});
      })
      state.tabs=lt;
      
      if (payload.userdata && payload.userdata.agentcodes && payload.userdata.agentcodes.length>0) {
        state.connection.zona = payload.userdata.agentcodes[0];
        if (payload.userdata.agentcodes.length>1) state.multizona=true;
      }
    },
    SET_LOGIN: function(state, payload)             { state.login = payload; },
    SET_GOOGLE_IDENTITY: function(state, payload) {
      state.googleIdentity = payload;
      var profile = payload.getBasicProfile();
      state.user.id_token=payload.getAuthResponse().id_token;
      state.user.id = profile.getId();
      state.user.name = profile.getName();
      state.user.imageUrl = profile.getImageUrl();
      state.user.email = profile.getEmail();
      //this.user.givename=this.profile.getGivenName();
      //this.user.familyname=this.profile.getFamilyName();
    },
  },
  modules: {
  },
  actions: {
    example: function(context) { context.commit(''); },
    loadMandati: function(context) {
      console.log('loadMandati');
      context.commit('SET_MANDATI',[]);
    },
    signOut: function(context) {
      // eslint-disable-next-line no-undef
      var auth2 = gapi.auth2.getAuthInstance();
      auth2.signOut().then(function() {
        console.log('User signed out.');
        context.commit('SET_LOGIN', false);
      });
    },
    initmeta: function(context) {
      console.log('initmeta');
      context.dispatch('gettabs');
    },
    gettabs: function(context) {
      return new Promise(function(resolve, reject) {
        console.log('gettabs');
        //metaremote.setSecret(context.getters.connection.secret);
        metaremote.get(context.getters.connection, 'tabs').then(function(res) {
          console.log('metaremote get');
          console.log(res);
          if (res) {
            context.commit('SET_TABS', res );
            context.commit('SET_ACTIVE_TAB', res[0].name);    
          }
          resolve();
        }).catch(function(err) {
          reject(err);
        });
      });
    },
    getattachment: function( context, payload ) {
      return new Promise(function(resolve) {
        console.log('getattachment');
        var data={ callname:'getAttachment', docId: payload.docId};
        apiclient.getdata( context.state.connection, data).then( function(resp) {
          console.log('getattachment:');
          console.log(resp)
          // context.commit('SET_PIATTO', resp );
          resolve(resp);
        });
      })
    },  
  }
})
